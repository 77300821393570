import { IAiConversation } from '../components/ai/types';
import { IAuthentication } from '../components/common/types';
import { httpDelete, httpGet, httpPost } from './common';
import { ApiResult } from './types';

export const gptQuestion = async (authentication: IAuthentication, conversation: IAiConversation): Promise<ApiResult> => {
   try {
      return await httpPost('ai/question', conversation, authentication);
   } catch (err) {
      throw err;
   }
};

export const getGptConversationList = async (authentication: IAuthentication):Promise<ApiResult> => {
    try {
        return await httpGet('ai/conversation/list', authentication);
    } catch (err) {
        throw err;
    }
}

export const getGptConversation = async (authentication:IAuthentication, startDate:string):Promise<ApiResult> => {
    try {
        return await httpGet(`ai/conversation/${startDate}`, authentication);
    } catch (err) {
        throw err;
    }
}

export const deleteGptConversation = async (authentication: IAuthentication, deleteDate:string):Promise<ApiResult> => {
    try {
        return await httpDelete(`ai/conversation/${deleteDate}`,authentication);
    } catch (err) {
        throw err;
    }
}