import { useContext, useEffect, useState } from 'react';
import { AuthenticationContext } from '../../contexts/context';
import { IWord, IWordCount, WordEditorType, WordSearchColumn } from './types';
import { deleteWord, getWordCount, getWordList } from '../../services/word';
import { DeleteFilled, EditFilled, AddSquareFilled, SearchFilled } from '@fluentui/react-icons';
import {
   Button,
   DataGrid,
   DataGridBody,
   DataGridCell,
   DataGridHeader,
   DataGridHeaderCell,
   DataGridRow,
   Dropdown,
   Input,
   Label,
   Menu,
   MenuList,
   MenuPopover,
   MenuTrigger,
   Option,
   Slider,
   Spinner,
   Switch,
   TableCellLayout,
   TableColumnDefinition,
   TableRowId,
   Text,
   Title2,
   createTableColumn,
   tokens,
} from '@fluentui/react-components';
import { useWordStyles } from './styles';
import { useCommonStyles } from '../common/styles';
import { WordEditor } from './controls/WordEditor';
import { ConfirmDialog } from '../common/controls/ConfirmDialog';
import { Pagination } from '../common/controls/Pagination';
import {StopFilled, SettingsFilled, PlayFilled} from '@fluentui/react-icons';
import { textToSpeak } from '../../utils/tts.utils';
import { TextToSpeakLanguage } from '../common/types';

export const Word: React.FC = () => {
   const { authentication } = useContext(AuthenticationContext);
   const classes = useWordStyles();
   const [searchText, setSearchText] = useState<string>('');
   const [words, setWords] = useState<IWord[]>([]);
   const [searchColumn, setSearchColumn] = useState<WordSearchColumn>(WordSearchColumn.EnWord);
   const [totalPage, setTotalPage] = useState<number>(1);
   const [currentPageNum, setCurrentPageNum] = useState<number>(1);
   const [wordCount, setWordCount] = useState<IWordCount>({ memorizeWord: 0, totalWord: 0 });
   const [isEditWord, setIsEditWord] = useState<boolean>(false);
   const [selectedWords, setSelectedWords] = useState<IWord[]>([]);
   const [selectedItems, setSelectedItems] = useState<number[]>([]);
   const [editorType, setEditorType] = useState<WordEditorType>();
   const [isDeleteConfirm, setIsDeleteConfirm] = useState<boolean>(false);
   const [isDataLoaded, setIsDataLoaded] = useState<boolean>(true);
   const [isOnlyEnglish, setIsOnlyEnglish] = useState<boolean>(false);
   const [rate, setRate] = useState<number>(1);
   const [isSpeaking, setIsSpeaking] = useState<boolean>(false);

   useEffect(() => {
      getWordItems();
      getCount();
   }, [currentPageNum]); // eslint-disable-line react-hooks/exhaustive-deps

   const getCount = async () => {
      const result = await getWordCount(authentication!);
      if (result.isSuccess) {
         setWordCount(result.data);
      }
   };

   const columns: TableColumnDefinition<IWord>[] = [
      createTableColumn<IWord>({
         columnId: 'index',
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>No</div>;
         },
         renderCell: (word) => {
            return (
               <TableCellLayout style={{ justifyContent: 'center' }}>
                  {words.findIndex((element) => element.wordCode === word.wordCode) + 1}
               </TableCellLayout>
            );
         },
      }),
      createTableColumn<IWord>({
         columnId: 'enWord',
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>영단어</div>;
         },
         renderCell: (word) => {
            return <TableCellLayout style={{ justifyContent: 'center' }}>{word.enWord}</TableCellLayout>;
         },
      }),
      createTableColumn<IWord>({
         columnId: 'krWord',
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>뜻</div>;
         },
         renderCell: (word) => {
            return <TableCellLayout style={{ justifyContent: 'center' }}>{word.krWord}</TableCellLayout>;
         },
      }),
      createTableColumn<IWord>({
         columnId: 'createDate',
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>등록일</div>;
         },
         renderCell: (word) => {
            return <TableCellLayout style={{ justifyContent: 'center' }}>{word.createDate}</TableCellLayout>;
         },
      }),
      createTableColumn<IWord>({
         columnId: 'isMemorize',
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>암기 여부</div>;
         },
         renderCell: (word) => {
            return <TableCellLayout style={{ justifyContent: 'center' }}>{word.isMemorize}</TableCellLayout>;
         },
      }),
      createTableColumn<IWord>({
         columnId: 'remarks',
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>비고</div>;
         },
         renderCell: (word) => {
            return <TableCellLayout style={{ justifyContent: 'center' }}>{word.remarks}</TableCellLayout>;
         },
      }),
   ];

   const columnSizingOptions = {
      index: {
         defaultWidth: 60,
         minWidth: 50,
      },
      enWord: {
         defaultWidth: 150,
         minWidth: 50,
      },
      krWord: {
         defaultWidth: 150,
         minWidth: 50,
      },
      createDate: {
         defaultWidth: 150,
         minWidth: 50,
      },
      isMemorize: {
         defaultWidth: 100,
         minWidth: 50,
      },
      remarks: {
         defaultWidth: 100,
         minWidth: 100,
      },
   };

   const options = [
      { value: WordSearchColumn.EnWord, text: '영단어' },
      { value: WordSearchColumn.KrWord, text: '뜻' },
      { value: WordSearchColumn.Remarks, text: '비고' },
   ];

   const getWordItems = async () => {
      setIsDataLoaded(false);
      const getItems = await getWordList(authentication!, searchText, searchColumn, currentPageNum);
      if (getItems.isSuccess) {
         setWords(getItems.data.words);
         setTotalPage(getItems.data.totalPage);
      }
      setIsDataLoaded(true);
   };

   const onChangeWord = (word: IWord) => {
      let newWords: IWord[] = [...words];
      const index = newWords.findIndex((element) => element.wordCode === word.wordCode);
      if (index > -1) {
         newWords.splice(index, 1, word);
         setWords(newWords);
         getCount();
      } else {
         getWordItems();
      }
      setEditorType(undefined);
      setIsEditWord(false);
   };

   const onDeleteWord = async () => {
      let deleteWords: IWord[] = [];
      selectedItems.forEach((element) => {
         deleteWords.push(words[element]);
      });
      const result = await deleteWord(authentication!, deleteWords);
      if (result.isSuccess) {
         getWordItems();
      }
      setSelectedItems([]);
      setIsDeleteConfirm(false);
   };

   const stopSpeak = () => {
      setIsSpeaking(false);
      window.speechSynthesis.cancel();
   }

   const startSpeak = async() => {
      setIsSpeaking(true);
      if (isOnlyEnglish) {
         for (const element of selectedWords) {
            await textToSpeak(element.enWord, TextToSpeakLanguage.En, rate);
         }
      } else {
         for (const element of selectedWords) {
            await textToSpeak(element.enWord, TextToSpeakLanguage.En, rate);
            await textToSpeak(element.krWord, TextToSpeakLanguage.Kr, rate);
         }
      }
      setIsSpeaking(false);
   }

   return (
      <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}>
         <div className={classes.wordWrapper}>
            <div
               style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  height: '100%',
                  padding: '10px 20px',
                  boxSizing: 'border-box',
               }}
            >
               <div className={useCommonStyles().titleWrapper}>
                  <Title2>단어장</Title2>
               </div>
               <div className={useCommonStyles().countWrapper}>
                  <Text weight="semibold">등록 단어 : {wordCount.totalWord}</Text>
                  <Text weight="semibold">암기 단어 : {wordCount.memorizeWord}</Text>
               </div>
               <div className={classes.wordToolsWrapper}>
                  <div>
                     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', paddingBottom: 5 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                           <Button
                              appearance="transparent"
                              icon={isSpeaking ? <StopFilled /> : <PlayFilled />}
                              onClick={isSpeaking ? stopSpeak : startSpeak}
                              style={{ minWidth: 0, padding: 0 }}
                              disabled={selectedItems.length === 0}
                           >
                              {isSpeaking ? '정지' : '듣기'}
                           </Button>
                           <Menu positioning={'before-bottom'}>
                              <MenuTrigger>
                                 <Button
                                    icon={<SettingsFilled />}
                                    appearance="transparent"
                                    style={{ minWidth: 0, padding: 0, paddingLeft: 5 }}
                                 >
                                    설정
                                 </Button>
                              </MenuTrigger>
                              <MenuPopover>
                                 <MenuList>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                       <Switch
                                          checked={isOnlyEnglish}
                                          onChange={(event, data) => setIsOnlyEnglish(data.checked)}
                                          label={'영어만'}
                                          style={{ fontWeight: tokens.fontWeightSemibold }}
                                       />
                                       <Slider
                                          value={rate}
                                          max={1.5}
                                          min={0.5}
                                          step={0.5}
                                          onChange={(event, data) => setRate(data.value)}
                                       />
                                       <Label weight="semibold">속도 x{rate}</Label>
                                    </div>
                                 </MenuList>
                              </MenuPopover>
                           </Menu>
                        </div>
                     </div>
                     <div className={classes.wordToolsEditBox}>
                        <Button
                           appearance="transparent"
                           icon={<AddSquareFilled />}
                           onClick={() => {
                              setIsEditWord(true);
                              setEditorType(WordEditorType.Add);
                           }}
                           style={{ minWidth: 0, paddingLeft: 0, paddingRight: 0 }}
                        >
                           추가
                        </Button>
                        <Button
                           appearance="transparent"
                           icon={<EditFilled />}
                           style={{ minWidth: 0, paddingLeft: 0, paddingRight: 0 }}
                           onClick={() => {
                              setIsEditWord(true);
                              setEditorType(WordEditorType.Update);
                           }}
                           disabled={selectedItems.length !== 1}
                        >
                           수정
                        </Button>
                        <Button
                           appearance="transparent"
                           icon={<DeleteFilled />}
                           style={{ minWidth: 0, paddingLeft: 0, paddingRight: 0 }}
                           onClick={() => setIsDeleteConfirm(true)}
                           disabled={selectedItems.length <= 0 || editorType === WordEditorType.Update}
                        >
                           삭제
                        </Button>
                     </div>
                  </div>
                  <div className={classes.wordToolsSearchBox}>
                     <Dropdown
                        value={options.find((element) => element.value === searchColumn)?.text}
                        onOptionSelect={(event, data) => {
                           setSearchColumn(data.optionValue as WordSearchColumn);
                        }}
                        size="medium"
                        style={{ minWidth: '100px' }}
                     >
                        {options.map((option) => (
                           <Option key={option.value} value={option.value}>
                              {option.text}
                           </Option>
                        ))}
                     </Dropdown>
                     <Input
                        placeholder="검색할 값을 입력해주세요"
                        value={searchText}
                        onChange={(event, data) => setSearchText(data.value)}
                        style={{ width: 200 }}
                        onKeyDown={(event) => {
                           if (event.code === 'Enter') getWordItems();
                        }}
                     />
                     <Button appearance="primary" icon={<SearchFilled />} onClick={getWordItems}>
                        검색
                     </Button>
                  </div>
               </div>
               <DataGrid
                  items={words}
                  columns={columns}
                  selectionMode={'multiselect'}
                  selectedItems={selectedItems}
                  columnSizingOptions={columnSizingOptions}
                  onSelectionChange={(event, data) => {
                     const set = new Set<TableRowId>(data.selectedItems);
                     const indexs = Array.from(set) as number[];
                     let newSelectedWords: IWord[] = [];
                     indexs.forEach((index) => {
                        newSelectedWords.push(words[index]);
                     });
                     setSelectedItems(indexs);
                     setSelectedWords(newSelectedWords);
                  }}
                  style={{ height: '100%' }}
                  size="small"
                  sortable
                  resizableColumns
               >
                  <DataGridHeader style={{ textAlign: 'center' }}>
                     <DataGridRow>{({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}</DataGridRow>
                  </DataGridHeader>
                  {isDataLoaded ? (
                     <div style={{ cursor: 'pointer' }}>
                        <DataGridBody<IWord>>
                           {({ item, rowId }) => (
                              <DataGridRow<IWord> key={rowId}>
                                 {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
                              </DataGridRow>
                           )}
                        </DataGridBody>
                     </div>
                  ) : (
                     <Spinner size="small" style={{ height: '100%' }} />
                  )}
               </DataGrid>
               <Pagination currentPage={currentPageNum} totalPage={totalPage} onChangeCurrentPage={setCurrentPageNum} />
            </div>
            <WordEditor
               isOpen={isEditWord}
               word={selectedWords[0]}
               type={editorType}
               onDismiss={() => {
                  setIsEditWord(false);
                  setEditorType(undefined);
               }}
               onChangeWord={onChangeWord}
            />
         </div>
         <ConfirmDialog
            isOpen={isDeleteConfirm}
            title="삭제"
            subText="정말로 삭제하시겠습니까?"
            onSuccess={onDeleteWord}
            onDismiss={() => setIsDeleteConfirm(false)}
         />
      </div>
   );
};
