import { TextToSpeakLanguage } from '../components/common/types';

export const textToSpeak = (text: string, lang: TextToSpeakLanguage, rate?: number) => {
   return new Promise<void>((resolve, reject) => {
      let voices: SpeechSynthesisVoice[] = [];
      voices = window.speechSynthesis.getVoices();
      let voiceURI = '';

      const utterThis = new SpeechSynthesisUtterance(text);
      utterThis.lang = lang;
      if (lang === TextToSpeakLanguage.Kr) {
         voiceURI = 'Google 한국의';
      } else {
         voiceURI = 'Google US English';
      }

      let voice = voices.find((element) => element.voiceURI === voiceURI);
      if (voice) {
         utterThis.voice = voice;
         if (rate) {
            utterThis.rate = rate;
         }
      }

      utterThis.onend = () => {
         resolve();
      };

      window.speechSynthesis.speak(utterThis);
   });
};
