import { Button, Input, tokens, Text } from '@fluentui/react-components';
import { useCommonStyles } from './styles';
import { useContext, useRef, useState } from 'react';
import { AuthenticationContext } from '../../contexts/context';
import { changePassword, requestLogin } from '../../services/user';
import { successNotification, warningNotification } from '../../utils/notification.utils';
import { useNavigate } from 'react-router-dom';

export const MyInfo: React.FC = (props) => {
   const { authentication, login } = useContext(AuthenticationContext);
   const classes = useCommonStyles();
   const [password, setPassword] = useState<string>('');
   const [checkPassword, setCheckPassword] = useState<string>('');
   const [resetPassword, setResetPassword] = useState<string>('');
   const [isAutentication, setIsAutentication] = useState<boolean>(false);
   const resetPasswordRef = useRef<HTMLInputElement>(null);
   const checkPasswordRef = useRef<HTMLInputElement>(null);
   const navigate = useNavigate();
   
   const onClickLogin = async () => {
      const result = await requestLogin(authentication?.user.userId!, password);
      if (result.isSuccess) {
         login!(result.data);
         setIsAutentication(true);
      }
   };

   const onClickChangePassword = async () => {
      if (checkPassword === '') {
         checkPasswordRef.current?.focus();
         warningNotification('비밀번호 확인을 입력해주세요');
      } else if (resetPassword === '') {
         resetPasswordRef.current?.focus();
         warningNotification('변경할 비밀번호를 입력해주세요');
      } else if (checkPassword !== resetPassword) {
         resetPasswordRef.current?.focus();
         warningNotification('변경할 비밀번호화 비밀번호 확인이 일치하지 않습니다.');
      } else {
        const result = await changePassword(authentication!, authentication?.user.userId!, resetPassword);
        if(result.isSuccess){
            navigate('/');
            successNotification('변경 완료');
        }
      }
   };

   return (
      <div
         style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
      >
         {!isAutentication ? (
            <div className={classes.loginInputBoxWrapper} style={{ width: '400px', paddingBottom: 200 }}>
               <Text weight="semibold" size={800} style={{ textAlign: 'center', padding: 10 }}>
                  비밀번호 입력
               </Text>
               <Input
                  type="password"
                  placeholder="비밀번호를 입력해주세요"
                  value={password}
                  size="large"
                  onChange={(event, data) => setPassword(data.value)}
                  onKeyPress={(event) => {
                     if (event.code === 'Enter') onClickLogin();
                  }}
               />
               <Button
                  style={{ width: '100%', fontWeight: tokens.fontWeightSemibold, height: 40 }}
                  appearance="primary"
                  onClick={onClickLogin}
               >
                  확인
               </Button>
            </div>
         ) : (
            <div className={classes.loginInputBoxWrapper} style={{ width: '400px', height: 500, paddingBottom: 200 }}>
               <Text weight="semibold" size={800} style={{ textAlign: 'center', padding: 10 }}>
                  비밀번호 변경
               </Text>
               <Input
                  ref={resetPasswordRef}
                  type="password"
                  placeholder="변경할 비밀번호를 입력해주세요"
                  value={resetPassword}
                  size="large"
                  onChange={(event, data) => setResetPassword(data.value)}
                  onKeyPress={(event) => {
                     if (event.code === 'Enter') onClickChangePassword();
                  }}
               />
               <Input
                  ref={checkPasswordRef}
                  type="password"
                  placeholder="변경할 비밀번호를 다시 입력해주세요"
                  value={checkPassword}
                  size="large"
                  onChange={(event, data) => setCheckPassword(data.value)}
                  onKeyPress={(event) => {
                     if (event.code === 'Enter') onClickChangePassword();
                  }}
               />
               <Button
                  style={{ width: '100%', fontWeight: tokens.fontWeightSemibold, height: 40 }}
                  appearance="primary"
                  onClick={onClickChangePassword}
               >
                  변경
               </Button>
            </div>
         )}
      </div>
   );
};
