import { useContext, useEffect, useRef, useState } from 'react';
import { deleteGptConversation, getGptConversation, getGptConversationList, gptQuestion } from '../../services/ai';
import { AuthenticationContext } from '../../contexts/context';
import dayjs from 'dayjs';
import { Button, Card, Text, CardPreview, Title2, tokens, Title3, Input, Spinner, Checkbox, Divider } from '@fluentui/react-components';
import { IAiConversation, IAiMessage, MessageRoleType } from './types';
import { SendFilled, DeleteFilled } from '@fluentui/react-icons';
import { BigConversationBox } from './controls/BigConversationBox';
import { warningNotification } from '../../utils/notification.utils';
import { useAiStyles } from './styles';

export const Ai: React.FC = () => {
   const { authentication } = useContext(AuthenticationContext);
   const classes = useAiStyles();
   const [conversationStartDates, setConversationStartDates] = useState<string[]>([]);
   const [selectedStartDate, setSelectedStartDate] = useState<string>();
   const [conversation, setConversation] = useState<IAiConversation>({
      messages: [{ content: '안녕하세요! 무엇을 도와드릴까요?', role: MessageRoleType.Assistant }],
   });
   const [isLoding, setIsLoding] = useState<boolean>(false);
   const messageInputRef = useRef<HTMLInputElement>(null);
   const chatBoxRef = useRef<HTMLDivElement>(null);

   const [message, setMessage] = useState<string>('');
   useEffect(() => {
      getConversationlist();
   }, [conversation]);

   useEffect(() => {
      getConversation();
   }, [selectedStartDate]);

   useEffect(() => {
      if (conversation.messages[conversation.messages.length - 1].role === MessageRoleType.User) {
         question();
      }
      scrollToBottom();
   }, [conversation]); // eslint-disable-line react-hooks/exhaustive-deps

   const question = async () => {
      setIsLoding(true);
      scrollToBottom();
      const response = await gptQuestion(authentication!, conversation);
      if (response.isSuccess) {
         const newMessages: IAiMessage[] = [...conversation.messages];
         newMessages.push(response.data.message);
         setConversation({ messages: newMessages, startDate: response.data.startDate });
      }
      setIsLoding(false);
      messageInputRef.current?.focus();
   };

   const scrollToBottom = () => {
      if (chatBoxRef.current) {
         chatBoxRef.current?.scrollTo({ top: chatBoxRef.current.scrollHeight });
      }
   };

   const onClickSendMessage = () => {
      let newMessages: IAiMessage[] = [...conversation?.messages!];
      if (message !== '') {
         newMessages.push({ role: MessageRoleType.User, content: message });
      } else {
         warningNotification('메세지를 입력해 주세요', 'top-center');
         messageInputRef.current?.focus();
      }
      setConversation({ messages: newMessages, startDate: conversation.startDate });
      setMessage('');
   };

   const getConversationlist = async () => {
      const result = await getGptConversationList(authentication!);
      if (result.isSuccess) {
         setConversationStartDates(result.data);
      }
   };

   const getConversation = async () => {
      if (selectedStartDate) {
         const result = await getGptConversation(authentication!, selectedStartDate!);
         if (result.isSuccess) {
            setConversation({
               messages: [{ content: '안녕하세요! 무엇을 도와드릴까요?', role: MessageRoleType.Assistant }, ...result.data.messages],
               startDate: selectedStartDate,
            });
         }
      }
   };

   const onClickNewConverSation = () => {
      setConversation({ messages: [{ content: '안녕하세요! 무엇을 도와드릴까요?', role: MessageRoleType.Assistant }] });
   };

   const onClickDelete = async (date: string) => {
      const result = await deleteGptConversation(authentication!, date);
      if (result.isSuccess) {
         let newConversationStartDates: string[] = [...conversationStartDates];
         newConversationStartDates = newConversationStartDates.filter((element) => element !== date);
         setConversationStartDates(newConversationStartDates);
         setSelectedStartDate(undefined);
         setConversation({
            messages: [{ content: '안녕하세요! 무엇을 도와드릴까요?', role: MessageRoleType.Assistant }],
         });
      }
   };

   return (
      <div
         style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', gap: 10, padding: 10, boxSizing: 'border-box' }}
      >
         <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
            <div
               style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 10,
                  width: '40%',
                  height: '100%',
                  border: '1px solid #e0e0e0',
                  borderRight: 0,
                  boxSizing: 'border-box',
               }}
            >
               <div style={{ borderBottom: '1px solid #e0e0e0', height: 43, boxSizing: 'border-box', padding: 10 }}>
                  <Text size={500} weight="semibold" style={{ userSelect: 'none' }}>
                     대화 목록
                  </Text>
               </div>
               <div style={{ height: '100%', overflowY: 'auto', padding: 10 }}>
                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10,
                        boxSizing: 'border-box',
                        width: '100%',
                     }}
                  >
                     {conversationStartDates.map((value, index) => (
                        <div className={classes.cardWrapper} key={index} onClick={() => setSelectedStartDate(value)}>
                           <div className={classes.cardTitle}>
                              <div style={{ display: 'flex', fontWeight: tokens.fontWeightSemibold, alignItems: 'center' }}>
                                 {dayjs(value).format('YYYY년MM월DD일(HH:mm:ss)')} 대화 이어가기
                              </div>
                           </div>
                           <Button
                              appearance="subtle"
                              style={{ minWidth: 0, width: 'auto', height: '100%', borderRadius: '0px 5px 5px 0px' }}
                              onClick={() => onClickDelete(value)}
                              icon={<DeleteFilled />}
                           />
                        </div>
                     ))}
                  </div>
               </div>
            </div>
            <div style={{ height: '100%', width: '60%', border: '1px solid #e0e0e0', boxSizing: 'border-box' }}>
               <div
                  style={{
                     display: 'flex',
                     borderBottom: '1px solid #e0e0e0',
                     height: 43,
                     boxSizing: 'border-box',
                     alignItems: 'center',
                     paddingLeft: 10,
                  }}
               >
                  <Button appearance="primary" onClick={onClickNewConverSation} disabled={isLoding}>
                     새로운 대화
                  </Button>
               </div>
               <div style={{ height: 'calc(100% - 43px)', padding: 10, boxSizing: 'border-box' }}>
                  <div
                     ref={chatBoxRef}
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10,
                        backgroundColor: tokens.colorBrandBackground,
                        borderRadius: 5,
                        height: 'calc(100% - 42px)',
                        overflowY: 'auto',
                        padding: 10,
                        boxSizing: 'border-box',
                     }}
                  >
                     {conversation?.messages.map((value, index) => (
                        <BigConversationBox message={value} key={`${index}_${value.content}`} />
                     ))}
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 5, width: '100%', gap: 5 }}>
                     {!isLoding ? (
                        <Input
                           placeholder="내용을 입력해주세요"
                           ref={messageInputRef}
                           style={{ width: '100%' }}
                           value={message}
                           onChange={(event, data) => setMessage(data.value)}
                           onKeyPress={(event) => {
                              if (event.code === 'Enter') onClickSendMessage();
                           }}
                        />
                     ) : (
                        <Spinner size="extra-small" style={{ width: '100%' }} label={'답변중..'} />
                     )}
                     <Button appearance="primary" style={{ minWidth: 0 }} icon={<SendFilled />} onClick={onClickSendMessage} />
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
