import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Spinner } from '@fluentui/react-components';
import { QuestionCircleRegular } from '@fluentui/react-icons';
interface PConfirmDialog {
   isOpen: boolean;
   onDismiss?: () => void;
   title: string;
   subText: string;
   onSuccess: () => void;
   isLoding?: boolean;
}

export const ConfirmDialog: React.FC<PConfirmDialog> = (props) => {
   return (
      <Dialog modalType="modal" open={props.isOpen} onOpenChange={props.onDismiss}>
         <DialogSurface style={{ width: '400px' }}>
            {props.isLoding ? (
               <div style={{ width: '100%', height: '100%' }}>
                  <DialogTitle style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                     <QuestionCircleRegular />
                     {props.title}
                  </DialogTitle>
                  <Spinner label={"저장중..."} labelPosition='below'/>
               </div>
            ) : (
               <DialogBody>
                  <DialogTitle style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                     <QuestionCircleRegular />
                     {props.title}
                  </DialogTitle>
                  <DialogContent>{props.subText}</DialogContent>
                  <DialogActions>
                     <Button appearance="primary" onClick={props.onSuccess}>
                        예
                     </Button>
                     <Button appearance="secondary" onClick={props.onDismiss}>
                        아니오
                     </Button>
                  </DialogActions>
               </DialogBody>
            )}
         </DialogSurface>
      </Dialog>
   );
};
