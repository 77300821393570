import { MemorizeType } from "../common/types";

export const enum WordSearchColumn {
   EnWord = 'enWord',
   KrWord = 'krWord',
   Remarks = 'remarks',
}

export const enum WordEditorType {
   Add = 'add',
   Update = 'update'
}

export interface IWord {
   wordCode?: number;
   userId: string;
   enWord: string;
   krWord: string;
   createDate: string;
   isMemorize?: MemorizeType;
   remarks: string;
}

export interface IWordCount {
   totalWord: number;
   memorizeWord: number;
}
