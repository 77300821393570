import { IAuthentication } from "../components/common/types";
import { httpGet } from "./common";
import { ApiResult } from "./types";

export const getRecommendWord = async (authentication:IAuthentication):Promise<ApiResult> => {
    try {
        return await httpGet('/recommend/word', authentication)
    } catch (err) {
        throw err;
    }
}

export const getRecommendSentence = async (authentication:IAuthentication):Promise<ApiResult> => {
    try {
        return await httpGet('/recommend/sentence',authentication);
    } catch(err){
        throw err;
    }
}