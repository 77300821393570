import { useContext, useEffect, useState } from 'react';
import utc  from 'dayjs/plugin/utc';
import {
   DataGrid,
   DataGridBody,
   DataGridCell,
   DataGridHeader,
   DataGridHeaderCell,
   DataGridRow,
   TableCellLayout,
   TableColumnDefinition,
   Text,
   createTableColumn,
   tokens,
} from '@fluentui/react-components';
import { getRecommendLog } from '../../../services/admin';
import { AuthenticationContext } from '../../../contexts/context';
import dayjs from 'dayjs';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import { IRecommendLog, WorkState } from '../types';
import { Pagination } from '../../common/controls/Pagination';

export const LogInfo: React.FC = () => {
   const { authentication } = useContext(AuthenticationContext);
   const [startDate, setStartDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
   const [endDate, setEndDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
   const [logDataes, setLogDataes] = useState<IRecommendLog[]>([]);
   const [totalPage, setTotalPage] = useState<number>(1);
   const [currentPage, setCurrentPage] = useState<number>(1);
   dayjs.extend(utc);

   useEffect(() => {
      getLogList();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      getLogList();
   }, [startDate, endDate, currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

   const getLogList = async () => {
      const result = await getRecommendLog(authentication!, startDate, endDate, currentPage, 10);
      if (result.isSuccess) {
         setLogDataes(result.data.logRecommendStoreList);
         setTotalPage(result.data.totalPage);
      }
   };

   const datePickerFormat = (date?: Date) => {
      return dayjs(date).format('YYYY-MM-DD');
   };

   const columns: TableColumnDefinition<IRecommendLog>[] = [
      createTableColumn<IRecommendLog>({
         columnId: 'index',
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>No</div>;
         },
         renderCell: (item) => {
            return (
               <TableCellLayout style={{ justifyContent: 'center' }}>
                  {logDataes.findIndex((element) => element.logCode === item.logCode) + 1}
               </TableCellLayout>
            );
         },
      }),
      createTableColumn<IRecommendLog>({
         columnId: 'workName',
         compare: (a, b) => {
            return a.workName.localeCompare(b.workName);
         },
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>작업 종류</div>;
         },
         renderCell: (item) => {
            return <TableCellLayout style={{ justifyContent: 'center' }}>{item.workName.replace('Recommend', '')}</TableCellLayout>;
         },
      }),
      createTableColumn<IRecommendLog>({
         columnId: 'startDate',
         compare: (a, b) => {
            return a.startDate.localeCompare(b.startDate);
         },
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>시작 시간</div>;
         },
         renderCell: (item) => {
            return (
               <TableCellLayout style={{ justifyContent: 'center' }}>{dayjs(item.startDate).utc(true).format('YYYY-MM-DD HH:mm:ss')}</TableCellLayout>
            );
         },
      }),
      createTableColumn<IRecommendLog>({
         columnId: 'endDate',
         compare: (a, b) => {
            return a.endDate.localeCompare(b.endDate);
         },
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>종료 시간</div>;
         },
         renderCell: (item) => {
            return (
               <TableCellLayout style={{ justifyContent: 'center' }}>{dayjs(item.endDate).utc(true).format('YYYY-MM-DD HH:mm:ss')}</TableCellLayout>
            );
         },
      }),
      createTableColumn<IRecommendLog>({
         columnId: 'workMessage',
         compare: (a, b) => {
            return a.workMessage.localeCompare(b.workMessage);
         },
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>메세지</div>;
         },
         renderCell: (item) => {
            return <TableCellLayout style={{ justifyContent: 'center' }}>{item.workMessage}</TableCellLayout>;
         },
      }),
      createTableColumn<IRecommendLog>({
         columnId: 'state',
         compare: (a, b) => {
            return a.state.localeCompare(b.state);
         },
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>상태</div>;
         },
         renderCell: (item) => {
            return <TableCellLayout style={{ justifyContent: 'center' }}>{item.state}</TableCellLayout>;
         },
      }),
      createTableColumn<IRecommendLog>({
         columnId: 'worker',
         compare: (a, b) => {
            return a.worker.localeCompare(b.worker);
         },
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>작업자</div>;
         },
         renderCell: (item) => {
            return <TableCellLayout style={{ justifyContent: 'center' }}>{item.worker}</TableCellLayout>;
         },
      }),
   ];

   const columnSizingOptions = {
      index: {
         defaultWidth: 50,
         minWidth: 50,
      },
      workName: {
         defaultWidth: 80,
         minWidth: 80,
      },
      startDate: {
         defaultWidth: 180,
         minWidth: 120,
      },
      endDate: {
         defaultWidth: 180,
         minWidth: 120,
      },
      workMessage: {
         defaultWidth: 300,
         minWidth: 300,
      },
      state: {
         defaultWidth: 80,
         minWidth: 80,
      },
      worker: {
         defaultWidth: 60,
         minWidth: 60,
      },
   };

   return (
      <div
         style={{ padding: 10, display: 'flex', flexDirection: 'column', gap: 10, width: '100%', height: '100%', boxSizing: 'border-box' }}
      >
         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: 10 }}>
            <Text size={400} weight="semibold">
               조회 일자 :
            </Text>
            <DatePicker
               value={dayjs(startDate).toDate()}
               formatDate={datePickerFormat}
               onSelectDate={(date) => {
                  if (dayjs(endDate).isBefore(dayjs(date))) {
                     setEndDate(dayjs(date).format('YYYY-MM-DD'));
                  }
                  setStartDate(dayjs(date).format('YYYY-MM-DD'));
               }}
               style={{ width: 130 }}
               isMonthPickerVisible={false}
               showGoToToday
            />
            <Text size={400} weight="semibold">
               ~
            </Text>
            <DatePicker
               value={dayjs(endDate).toDate()}
               formatDate={datePickerFormat}
               onSelectDate={(date) => {
                  setEndDate(dayjs(date).format('YYYY-MM-DD'));
               }}
               style={{ width: 130 }}
               minDate={dayjs(startDate).toDate()}
               isMonthPickerVisible={false}
               showGoToToday
            />
         </div>
         <div>
            <DataGrid columns={columns} items={logDataes} columnSizingOptions={columnSizingOptions} resizableColumns sortable size="small">
               <DataGridHeader style={{ textAlign: 'center' }}>
                  <DataGridRow>{({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}</DataGridRow>
               </DataGridHeader>
               <div>
                  <DataGridBody<IRecommendLog>>
                     {({ item, rowId }) => (
                        <DataGridRow<IRecommendLog>
                           key={rowId}
                           style={{
                              backgroundColor: item.state === WorkState.Fail ? 'darkred' : undefined,
                              color: item.state === WorkState.Fail ? 'white' : undefined,
                              fontWeight: tokens.fontWeightSemibold,
                           }}
                        >
                           {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
                        </DataGridRow>
                     )}
                  </DataGridBody>
               </div>
            </DataGrid>
         </div>
         <div>
            <Pagination currentPage={currentPage} onChangeCurrentPage={setCurrentPage} totalPage={totalPage} />
         </div>
      </div>
   );
};
