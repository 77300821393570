import { makeStyles, shorthands } from "@fluentui/react-components";

export const useSentenceStyles = makeStyles({
    wrapper: {
        display:'flex',
        flexDirection:'row',
        height:'100%',
        width:'100%'
    },
    contentWrapper: {
        display:'flex',
        flexDirection:'column',
        width:'100%',
        height:'100%',
        boxSizing:'border-box',
        ...shorthands.padding('10px', '20px')
    },
    toolsWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '10px',
    },
    toolsEditContentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems:'end',
        ...shorthands.gap('5px'),
    },

})