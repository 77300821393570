import { IAuthentication, IUser } from '../components/common/types';
import { httpGet, httpPost } from './common';
import { ApiResult } from './types';

export const getUserInfo = async (): Promise<ApiResult> => {
   try {
      return await httpGet(`/user/info`);
   } catch (err) {
      throw err;
   }
};

export const requestLogin = async (userId: string, password: string): Promise<ApiResult> => {
   try {
      return await httpPost('/user/login', { userId: userId, password: password });
   } catch (err) {
      throw err;
   }
};

export const changePassword = async (authentication: IAuthentication, userId: string, password: string): Promise<ApiResult> => {
   try {
      return await httpPost('user/password/change', { userId: userId, password: password }, authentication);
   } catch (err) {
      throw err;
   }
};

export const getVerificationMail = async (mail: string): Promise<ApiResult> => {
   try {
      return await httpGet(`user/verification/mail/${mail}`);
   } catch (err) {
      throw err;
   }
};

export const checkMailCode = async (mail: string, code: string): Promise<ApiResult> => {
   try {
      return await httpGet(`user/check/mail/${mail}?code=${code}`);
   } catch (err) {
      throw err;
   }
};

export const checkUserId = async (userId: string): Promise<ApiResult> => {
   try {
      return await httpGet(`user/check/id/${userId}`);
   } catch (err) {
      throw err;
   }
};

export const userSignUp = async (user: IUser): Promise<ApiResult> => {
   try {
      return await httpPost('user/signup', user);
   } catch (err) {
      throw err;
   }
};

export const userMailCheck = async (mail: string): Promise<ApiResult> => {
   try {
      return await httpGet(`user/mail/${mail}`);
   } catch (err) {
      throw err;
   }
};

export const sendPasswordMail = async (mail: string): Promise<ApiResult> => {
   try {
      return await httpPost('user/findpassword', { mail: mail });
   } catch (err) {
      throw err;
   }
};

export const quitUser = async (authentication: IAuthentication): Promise<ApiResult> => {
   try {
      return await httpPost('user/quit', undefined, authentication);
   } catch (err) {
      throw err;
   }
};
