import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useAiStyles = makeStyles({
   cardWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: '50px',
      cursor: 'pointer',
      userSelect:'none',
      boxShadow: tokens.shadow4,
      ...shorthands.borderRadius('5px'),
      ':hover': {
         backgroundColor: tokens.colorBrandBackground2Hover,
      },
   },
   cardTitle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      ...shorthands.padding('10px'),
   },
});
