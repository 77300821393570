import { Avatar, tokens } from '@fluentui/react-components';
import { IAiMessage, MessageRoleType } from '../../ai/types';
import { useContext } from 'react';
import { AuthenticationContext } from '../../../contexts/context';

interface PSmallConversationBox {
   message: IAiMessage;
}

export const SmallConversationBox: React.FC<PSmallConversationBox> = (props) => {
   const { authentication } = useContext(AuthenticationContext);

   return (
      <>
         {props.message.role === MessageRoleType.Assistant ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
               <Avatar color="peach" name="A I" />
               <div
                  style={{
                     backgroundColor: '#fff',
                     paddingLeft: '5px',
                     width: '0px',
                     height: '0px',
                     borderTop: `5px solid ${tokens.colorBrandBackgroundInvertedSelected}`,
                     borderBottom: `5px solid ${tokens.colorBrandBackgroundInvertedSelected}`,
                     borderLeft: '0px solid transparent',
                     borderRight: '5px solid #fff',
                  }}
               />
               <div
                  style={{
                     height: 'auto',
                     maxWidth: '280px',
                     padding: '5px',
                     borderRadius: 5,
                     backgroundColor: '#fff',
                     boxSizing: 'border-box',
                     wordBreak: 'break-all',
                  }}
               >
                  {props.message.content}
               </div>
            </div>
         ) : (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'end' }}>
               <div
                  style={{
                     height: 'auto',
                     maxWidth: '280px',
                     padding: '5px',
                     borderRadius: 5,
                     backgroundColor: 'rgb(252,236,92)',
                     boxSizing: 'border-box',
                     wordBreak: 'break-all',
                  }}
               >
                  {props.message.content}
               </div>
               <div
                  style={{
                     paddingLeft: '5px',
                     width: '0px',
                     height: '0px',
                     borderTop: `5px solid ${tokens.colorBrandBackgroundInvertedSelected}`,
                     borderBottom: `5px solid ${tokens.colorBrandBackgroundInvertedSelected}`,
                     borderLeft: '5px solid rgb(252,236,92)',
                     borderRight: '0px solid transparent',
                  }}
               />
               <Avatar
                  color="peach"
                  icon={
                     <div
                        style={{ fontSize: 13 }}
                     >{`${authentication?.user.name[0].toUpperCase()}${authentication?.user.name[1].toUpperCase()}`}</div>
                  }
               />
            </div>
         )}
      </>
   );
};
