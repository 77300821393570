import { Button, Input, Spinner, tokens } from '@fluentui/react-components';
import { useContext, useEffect, useRef, useState } from 'react';
import { IAiConversation, IAiMessage, MessageRoleType } from '../../ai/types';
import { SmallConversationBox } from './SmallConversationBox';
import { SendFilled } from '@fluentui/react-icons';
import { AuthenticationContext } from '../../../contexts/context';
import { gptQuestion } from '../../../services/ai';
import { warningNotification } from '../../../utils/notification.utils';
import { DismissFilled } from '@fluentui/react-icons';

interface PAiSmallChat {
   onDismiss: () => void;
   isOpen: boolean;
}

export const AiSmallChat: React.FC<PAiSmallChat> = (props) => {
   const { authentication } = useContext(AuthenticationContext);
   const [message, setMessage] = useState<string>('');
   const [isLoding, setIsLoding] = useState<boolean>(false);
   const [conversation, setConversation] = useState<IAiConversation>({messages:[{content:'안녕하세요! 무엇을 도와드릴까요?',role:MessageRoleType.Assistant}]});
   const messageInputRef = useRef<HTMLInputElement>(null);
   const chatBoxRef = useRef<HTMLDivElement>(null);

   useEffect(() => {
      if (props.isOpen) {
         messageInputRef.current?.focus();
      }
   }, [props.isOpen]);

   useEffect(() => {
      if (conversation.messages[conversation.messages.length - 1].role === MessageRoleType.User) {
         question();
      }
      scrollToBottom();
   }, [conversation]); // eslint-disable-line react-hooks/exhaustive-deps

   const scrollToBottom = () => {
      if (chatBoxRef.current) {
         chatBoxRef.current?.scrollTo({ top: chatBoxRef.current.scrollHeight });
      }
   };
   
   const question = async () => {
      setIsLoding(true);
      scrollToBottom();
      const response = await gptQuestion(authentication!, conversation);
      if (response.isSuccess) {
         const newMessages: IAiMessage[] = [...conversation.messages];
         newMessages.push(response.data.message);
         setConversation({messages:newMessages , startDate:response.data.startDate});
      }
      setIsLoding(false);
      messageInputRef.current?.focus();
   };

   const onClickSendMessage = () => {
      let newMessages: IAiMessage[] = [...conversation.messages];
      if (message !== '') {
         newMessages.push({ role: MessageRoleType.User, content: message });
      } else {
         warningNotification('메세지를 입력해 주세요', 'top-center');
         messageInputRef.current?.focus();
      }
      setConversation({messages: newMessages, startDate: conversation.startDate});
      setMessage('');
   };

   return (
      <div
         style={{
            position: 'absolute',
            width: 400,
            height: '70vh',
            bottom: 50,
            right: 0,
            borderRadius: 5,
            backgroundColor: tokens.colorBrandBackgroundInvertedSelected,
            padding: 5,
            boxSizing: 'border-box',
            boxShadow: '1px 1px 2px 1px rgb(218, 220, 224, 0.5)',
            zIndex: 10000000,
         }}
         onKeyDown={(event) => {
            if (event.code === 'Escape') props.onDismiss();
         }}
      >
         <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', border: `2px solid #fff`, borderBottom: '0px' }}>
            <Button icon={<DismissFilled />} appearance="transparent" onClick={props.onDismiss} />
         </div>
         <div
            ref={chatBoxRef}
            style={{
               display: 'flex',
               flexDirection: 'column',
               border: `2px solid #fff`,
               borderTop: '0px',
               height: 'calc(100% - 74px)',
               overflowY: 'auto',
               borderRadius: '0px 0px 5px 5px',
               padding: 5,
               boxSizing: 'border-box',
               gap: '10px',
            }}
         >
            {conversation.messages.map((value, index) => (
               <SmallConversationBox key={index} message={value} />
            ))}
         </div>
         <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 5, gap: 2 }}>
            {!isLoding ? (
               <Input
                  ref={messageInputRef}
                  placeholder="대화를 입력해주세요"
                  style={{ width: '100%' }}
                  value={message}
                  onChange={(event, data) => setMessage(data.value)}
                  onKeyPress={(event) => {
                     if (event.code === 'Enter') onClickSendMessage();
                  }}
                  autoFocus
               />
            ) : (
               <Spinner size="extra-small" style={{ width: '100%' }} label={'답변중..'} />
            )}
            <Button icon={<SendFilled />} appearance="primary" onClick={onClickSendMessage} disabled={isLoding} />
         </div>
      </div>
   );
};
