import { useContext, useEffect, useState } from 'react';
import { tokens, Text, Dropdown, Option, Divider } from '@fluentui/react-components';
import { DatabaseFilled } from '@fluentui/react-icons';
import { DatePicker } from '@fluentui/react-datepicker-compat';

import dayjs from 'dayjs';
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { getTokenCount, getTokenCountByDate, getTokenCountList } from '../../../services/admin';
import { AuthenticationContext } from '../../../contexts/context';
import { ITokenCount } from '../types';

export const TokenInfo: React.FC = () => {
   const { authentication } = useContext(AuthenticationContext);
   const [tokenCountList, setTokenCountList] = useState<ITokenCount[]>([]);
   const [tokenCount, setTokenCount] = useState<ITokenCount>({ completionTokens: 0, promptTokens: 0, totalTokens: 0 });
   const [tokenCountByDate, setTokenCountByDate] = useState<ITokenCount>({ completionTokens: 0, promptTokens: 0, totalTokens: 0 });
   const [startDate, setStartDate] = useState<Date>(new Date());
   const [endDate, setEndDate] = useState<Date>(new Date());
   const [selectedListCount, setSelectedListCount] = useState<number>(5);

   useEffect(() => {
      initData();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      getTokenCountListAsync();
   }, [selectedListCount]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      getTokenCountbyDateAsync();
   }, [startDate, endDate]); // eslint-disable-line react-hooks/exhaustive-deps

   const initData = async () => {
      const tokenCountRes = await getTokenCount(authentication!);
      getTokenCountbyDateAsync();
      if (tokenCountRes.isSuccess) {
         setTokenCount(tokenCountRes.data);
      }
      getTokenCountListAsync();
   };

   const getTokenCountbyDateAsync = async () => {
      const tokenCountDateRes = await getTokenCountByDate(
         authentication!,
         dayjs(startDate).format('YYYY-MM-DD'),
         dayjs(endDate).format('YYYY-MM-DD')
      );

      if (tokenCountDateRes.isSuccess) {
         setTokenCountByDate(tokenCountDateRes.data);
      }
   };

   const getTokenCountListAsync = async () => {
      const usageListRes = await getTokenCountList(authentication!, selectedListCount);

      if (usageListRes.isSuccess) {
         const dateList: string[] = [];
         for (let i = selectedListCount - 1; i >= 0; --i) {
            dateList.push(dayjs().add(-i, 'days').format('YYYY-MM-DD'));
         }

         let newTokenCountList: ITokenCount[] = [];
         dateList.forEach((value, index) => {
            let data: ITokenCount = usageListRes.data.find((element: any) => element.date === value);
            if (data) {
               newTokenCountList.push({
                  date: dayjs(data.date).format('M/DD'),
                  completionTokens: data.completionTokens,
                  promptTokens: data.promptTokens,
                  totalTokens: data.totalTokens,
               });
            } else {
               newTokenCountList.push({
                  date: dayjs(value).format('M/DD'),
                  completionTokens: 0,
                  promptTokens: 0,
                  totalTokens: 0,
               });
            }
         });

         setTokenCountList(newTokenCountList);
      }
   };

   return (
      <div
         style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            flexDirection: 'column',
            gap: 20,
            padding: 5,
            boxSizing: 'border-box',
         }}
      >
         <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Text weight="semibold" size={600}>
               총 사용량
            </Text>
            <div
               style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  gap: 10,
               }}
            >
               <div style={{ width: '100%', display: 'flex', gap: 10 }}>
                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: 'calc(100%/3)',
                        boxShadow: tokens.shadow4,
                        padding: 10,
                        borderRadius: 10,
                     }}
                  >
                     <Text weight="semibold" size={500}>
                        총 토큰
                     </Text>
                     <div>
                        {tokenCount.totalTokens.toLocaleString()} <DatabaseFilled />
                     </div>
                     <div>{((tokenCount.totalTokens / 1000) * 0.002).toLocaleString()} $</div>
                  </div>
                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: 'calc(100%/3)',
                        boxShadow: tokens.shadow4,
                        padding: 10,
                        borderRadius: 10,
                     }}
                  >
                     <Text weight="semibold" size={500}>
                        완료 토큰
                     </Text>
                     <div>
                        {tokenCount.completionTokens.toLocaleString()} <DatabaseFilled />
                     </div>
                     <div>{((tokenCount.completionTokens / 1000) * 0.002).toLocaleString()} $</div>
                  </div>
                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: 'calc(100%/3)',
                        boxShadow: tokens.shadow4,
                        padding: 10,
                        borderRadius: 10,
                     }}
                  >
                     <Text weight="semibold" size={500}>
                        프롬프트 토큰
                     </Text>
                     <div>
                        {tokenCount.promptTokens.toLocaleString()} <DatabaseFilled />
                     </div>
                     <div>{((tokenCount.promptTokens / 1000) * 0.002).toLocaleString()} $</div>
                  </div>
               </div>
            </div>
         </div>
         <Divider />
         <div
            style={{
               display: 'flex',
               flexDirection: 'column',
               width: '100%',
            }}
         >
            <div
               style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 10, padding: 5 }}
            >
               <Text weight="semibold" size={600}>
                  기간별 사용량
               </Text>
               <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                  <Text weight="semibold" size={400}>
                     일자 선택
                  </Text>
                  <DatePicker
                     value={startDate}
                     formatDate={(date) => dayjs(date).format('YYYY-MM-DD')}
                     onSelectDate={(date) => setStartDate(date!)}
                     appearance="underline"
                     maxDate={endDate}
                  />
                  ~
                  <DatePicker
                     value={endDate}
                     formatDate={(date) => dayjs(date).format('YYYY-MM-DD')}
                     onSelectDate={(date) => setEndDate(date!)}
                     appearance="underline"
                     minDate={startDate}
                  />
               </div>
            </div>
            <div style={{ width: '100%', display: 'flex', gap: 10 }}>
               <div
                  style={{
                     display: 'flex',
                     flexDirection: 'column',
                     width: 'calc(100%/3)',
                     boxShadow: tokens.shadow4,
                     padding: 10,
                     borderRadius: 10,
                  }}
               >
                  <Text weight="semibold" size={500}>
                     총 토큰
                  </Text>
                  <div>
                     {tokenCountByDate.totalTokens.toLocaleString()} <DatabaseFilled />
                  </div>
                  <div>{((tokenCountByDate.totalTokens / 1000) * 0.002).toLocaleString()} $</div>
               </div>
               <div
                  style={{
                     display: 'flex',
                     flexDirection: 'column',
                     width: 'calc(100%/3)',
                     boxShadow: tokens.shadow4,
                     padding: 10,
                     borderRadius: 10,
                  }}
               >
                  <Text weight="semibold" size={500}>
                     완료 토큰
                  </Text>
                  <div>
                     {tokenCountByDate.completionTokens.toLocaleString()} <DatabaseFilled />
                  </div>
                  <div>{((tokenCountByDate.completionTokens / 1000) * 0.002).toLocaleString()} $</div>
               </div>
               <div
                  style={{
                     display: 'flex',
                     flexDirection: 'column',
                     width: 'calc(100%/3)',
                     boxShadow: tokens.shadow4,
                     padding: 10,
                     borderRadius: 10,
                  }}
               >
                  <Text weight="semibold" size={500}>
                     프롬프트 토큰
                  </Text>
                  <div>
                     {tokenCountByDate.promptTokens.toLocaleString()} <DatabaseFilled />
                  </div>
                  <div>{((tokenCountByDate.promptTokens / 1000) * 0.002).toLocaleString()} $</div>
               </div>
            </div>
         </div>
         <Divider />
         <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <Text weight="semibold" size={600}>
               일자별 사용량
            </Text>
            <div
               style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: 10,
                  display: 'flex',
                  flexDirection: 'column',
                  boxShadow: tokens.shadow4,
               }}
            >
               <div style={{ display: 'flex', justifyContent: 'end', padding: 5 }}>
                  <Dropdown
                     style={{ minWidth: 0 }}
                     value={selectedListCount.toString() + '일'}
                     selectedOptions={[selectedListCount.toString()]}
                     onOptionSelect={(event, data) => setSelectedListCount(parseInt(data.optionValue!))}
                  >
                     <Option value={'5'}>5일</Option>
                     <Option value={'10'}>10일</Option>
                     <Option value={'15'}>15일</Option>
                     <Option value={'20'}>20일</Option>
                  </Dropdown>
               </div>
               <ResponsiveContainer width="100%" height="100%" minHeight={200}>
                  <BarChart
                     data={tokenCountList}
                     margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                     }}
                     barSize={50}
                     throttleDelay={10}
                  >
                     <XAxis dataKey={'date'} interval={0} />
                     <YAxis type="number" interval={0} tickSize={10} />
                     <Tooltip />
                     <Legend />
                     <Bar dataKey="completionTokens" name="완료 토큰" stackId="a" fill={tokens.colorBrandBackground} />
                     <Bar dataKey="promptTokens" name="프롬프트 토큰" stackId="a" fill={tokens.colorBackgroundOverlay} />
                  </BarChart>
               </ResponsiveContainer>
            </div>
         </div>
      </div>
   );
};
