import { Button, Input, Spinner, Text } from '@fluentui/react-components';
import { useRef, useState } from 'react';
import { successNotification, warningNotification } from '../../utils/notification.utils';
import { sendPasswordMail, userMailCheck } from '../../services/user';
import { useNavigate } from 'react-router-dom';

export const FindPassword: React.FC = () => {
   const [mail, setMail] = useState<string>('');
   const [isSending, setIsSending] = useState<boolean>(false);
   const emailInpuRef = useRef<HTMLInputElement>(null);
   const navigate = useNavigate();

   const onClickSendMail = async () => {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      setIsSending(true);
      if (mail && emailPattern.test(mail)) {
         const result = await userMailCheck(mail);
         if (result.isSuccess) {
            if (result.data) {
               const sendMailResult = await sendPasswordMail(mail);
               if (sendMailResult.data) {
                  successNotification('메일로 비밀번호를 전송하였습니다.');
                  navigate('/login');
               }
            } else {
               warningNotification('가입하지 않은 메일입니다.');
            }
         }
      } else {
         warningNotification('올바른 메일 형식이 아닙니다.');
      }
      setIsSending(false);
   };

   return (
      <div style={{ width: '100%', height: '100%' }}>
         {isSending && (
            <div style={{ width: '100%', height: '100%', position: 'absolute', background: '#ffffff', opacity: 0.5, zIndex: 997 }}>
               <Spinner labelPosition={'below'} label={'메일 전송중..'} style={{ height: '100%' }} />
            </div>
         )}

         <div style={{ display: 'flex', justifyContent: 'center', padding: 30 }}>
            <Text weight="semibold" size={700}>
               비밀번호 찾기
            </Text>
         </div>
         <div style={{ display: 'flex', justifyContent: 'center', gap: 10}}>
            <Input
               ref={emailInpuRef}
               value={mail}
               onChange={(event, data) => setMail(data.value)}
               placeholder="이메일"
               style={{ width: 300 }}
            />
            <Button appearance="primary" onClick={onClickSendMail}>
               전송
            </Button>
         </div>
      </div>
   );
};
