import { IAuthentication } from '../components/common/types';
import { ISentence } from '../components/sentence/types';
import { httpGet, httpPost, httpPut } from './common';
import { ApiResult } from './types';

export const getSentenceList = async (
   authentication: IAuthentication,
   searchColumn: string,
   searchText: string,
   currentPageNum: number
): Promise<ApiResult> => {
   try {
      return await httpGet(`sentence/list/${searchColumn}/${currentPageNum}?searchText=${searchText}`, authentication);
   } catch (err) {
      throw err;
   }
};

export const getSentenceCount = async (authentication: IAuthentication): Promise<ApiResult> => {
   try {
      return await httpGet('sentence/count', authentication);
   } catch (err) {
      throw err;
   }
};

export const saveSentence = async (authentication: IAuthentication, sentence: ISentence): Promise<ApiResult> => {
   try {
      return await httpPost('sentence', sentence, authentication);
   } catch (err) {
      throw err;
   }
};

export const updateSentence = async (authentication: IAuthentication, sentence: ISentence): Promise<ApiResult> => {
   try {
      return await httpPut('sentence', sentence, authentication);
   } catch (err) {
      throw err;
   }
};

export const deleteSentence = async (authentication: IAuthentication, sentences: ISentence[]): Promise<ApiResult> => {
   try {
      return await httpPut('sentence/list', sentences, authentication);
   } catch (err) {
      throw err;
   }
};

export const recentlySentence = async (authentication: IAuthentication): Promise<ApiResult> => {
   try {
      return await httpGet('sentence/recently', authentication);
   } catch (err) {
      throw err;
   }
};

export const overlapSentence = async (authentication: IAuthentication, enSentence: string): Promise<ApiResult> => {
   try {
      return await httpGet(`sentence/overlap?sentence=${enSentence}`, authentication);
   } catch (err) {
      throw err;
   }
};
