import { Tab, TabList, Text } from '@fluentui/react-components';
import { useMemo, useState } from 'react';
import { AdminTabs } from './types';
import { TokenInfo } from './controls/TokenInfo';
import { UserManage } from './controls/UserManage';
import { Schedule } from './controls/Schedule';
import { LogInfo } from './controls/LogInfo';

export const Admin: React.FC = () => {
   const [tabValue, setTabValue] = useState<AdminTabs>(AdminTabs.TokenInfo);

   const renderTab = useMemo(() => {
      switch (tabValue) {
         case AdminTabs.TokenInfo:
            return <TokenInfo />;
         case AdminTabs.UserInfo:
            return <UserManage />;
         case AdminTabs.Schedule:
            return <Schedule />;
         case AdminTabs.LogInfo:
            return <LogInfo />;
      }
   }, [tabValue]);

   return (
      <div style={{ width: '100%', height: '100%', padding: 10, boxSizing: 'border-box' }}>
         <div>
            <Text weight="semibold" size={600}>
               관리자
            </Text>
            <TabList selectedValue={tabValue} onTabSelect={(event, data) => setTabValue(data.value as AdminTabs)}>
               <Tab value={AdminTabs.TokenInfo}>토큰 관리</Tab>
               <Tab value={AdminTabs.UserInfo}>유저 관리</Tab>
               <Tab value={AdminTabs.Schedule}>예약 작업</Tab>
               <Tab value={AdminTabs.LogInfo}>로그 내역</Tab>
            </TabList>
         </div>
         <div
            style={{
               padding: 10,
               width: '100%',
               height: 'calc(100% - 76px)',
               boxSizing: 'border-box',
            }}
         >
            {renderTab}
         </div>
      </div>
   );
};
