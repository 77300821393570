import { makeStyles, shorthands } from '@fluentui/react-components';

export const useWordStyles = makeStyles({
   wordWrapper: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
      ...shorthands.gap('10px')
   },
   wordToolsWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '10px',
   },
   wordToolsEditBox: {
      display: 'flex',
      flexDirection: 'row',
      ...shorthands.gap('5px'),
   },
   wordToolsSearchBox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems:'end',
      ...shorthands.gap('5px'),
   },
});
