export interface ITokenUsage {
   tokenId: number;
   totalTokens: number;
   completionTokens: number;
   promptTokens: number;
   usageTime: string;
}

export enum AdminTabs {
   TokenInfo = 'tokenInfo',
   UserInfo = 'userInfo',
   Schedule = 'schedule',
   LogInfo = 'logInfo',
}

export interface ITokenCount {
   date?: string;
   totalTokens: number;
   completionTokens: number;
   promptTokens: number;
}

export enum UserSearchType {
   UserId = 'userId',
   Name = 'name',
   Mail = 'mail',
}

export enum ScheduleType {
   Word = 'word',
   Sentence = 'sentence',
}

export interface ISchedule {
   cron: string;
   userId: string;
}

export interface IRecommendLog {
   logCode: number;
   startDate: string;
   endDate: string;
   worker: string;
   workMessage: string;
   workName: string;
   state: WorkState;
}

export enum WorkState {
   Success = 'SUCCESS',
   Fail = 'FAIL',
}
