import { useContext, useState } from 'react';
import { IAuthentication, IAuthenticationContext, IMenuList, RoleName } from './types';
import { AuthenticationContext } from '../../contexts/context';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useCommonStyles } from './styles';

import { NavigationFilled, DismissFilled, ArchiveMultipleFilled } from '@fluentui/react-icons';
import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle } from '@fluentui/react-components/unstable';
import { Avatar, Button, Card, CardFooter, CardHeader, Text, mergeClasses, tokens } from '@fluentui/react-components';
import { Login } from './Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Home } from './Home';
import { Word } from '../word/Word';
import { Sentence } from '../sentence/Sentence';
import { Ai } from '../ai/Ai';
import { AiSmallChat } from './controls/AiSmallChat';
import { MyInfo } from './MyInfo';
import { Admin } from '../admin/Admin';
import { SignUp } from './SignUp';
import { FindPassword } from './FindPassword';
import { ConfirmDialog } from './controls/ConfirmDialog';
import { quitUser } from '../../services/user';
import { errorNotification, successNotification } from '../../utils/notification.utils';

interface PLayout {
   children?: React.ReactNode;
   authentication?: IAuthentication;
}

export const Layout: React.FC<PLayout> = (props) => {
   const classes = useCommonStyles();
   const { logout } = useContext<IAuthenticationContext>(AuthenticationContext);
   const [isSidePanelOpen, setIsSidePanelOpen] = useState<boolean>(false);
   const [isAIHover, setIsAIHover] = useState<boolean>(false);
   const [visibleChat, setVisibleChat] = useState<boolean>(false);
   const [isQuitSiteDialog, setIsQuitSiteDialog] = useState<boolean>(false);
   const [isActionLoding, setIsActionLoding] = useState<boolean>(false);
   const navigate = useNavigate();
   const location = useLocation();

   const onClickLoginAndLogout = () => {
      if (props.authentication) {
         logout!();
      } else {
         navigate('/login');
      }
   };

   const onQuitSite = async () => {
      if (props.authentication) {
         setIsActionLoding(true);
         const result = await quitUser(props.authentication);
         if (result.isSuccess) {
            logout!();
            navigate('/login');
            setIsSidePanelOpen(false);
            successNotification('회원 탈퇴가 완료 되었습니다.');
            setIsQuitSiteDialog(false);
         } else {
            errorNotification('회원 탈퇴가 실패 하였습니다.');
         }
         setIsActionLoding(false);
      }
   };

   const menuList: IMenuList[] = [
      { key: 'word', name: '단어장', url: '/word' },
      { key: 'sentence', name: '문장노트', url: '/sentence' },
      { key: 'ai', name: 'AI 학습', url: '/ai' },
   ];

   return (
      <div className={classes.layoutWrapper}>
         <div className={classes.layoutTopBarWrapper}>
            <NavigationFilled className={classes.layoutTopNavIcon} fontSize={25} onClick={() => setIsSidePanelOpen(!isSidePanelOpen)} />
            <div className={classes.layoutTopLogo} onClick={() => navigate('/')}>
               ES Talk
            </div>
            <div className={classes.layoutTopUserState} onClick={onClickLoginAndLogout}>
               {props.authentication ? '로그아웃' : '로그인'}
            </div>
         </div>
         <Drawer open={isSidePanelOpen} onOpenChange={(event, data) => setIsSidePanelOpen(data.open)}>
            <DrawerHeader>
               <DrawerHeaderTitle
                  action={<Button appearance="subtle" icon={<DismissFilled />} onClick={() => setIsSidePanelOpen(false)} />}
               >
                  <div
                     className={classes.layoutSidePenalHeaderTitle}
                     onClick={() => {
                        navigate('/');
                        setIsSidePanelOpen(false);
                     }}
                  >
                     ES Talk
                  </div>
               </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody style={{ padding: 0 }}>
               <div style={{ padding: 10 }}>
                  <Card
                     onClick={() => {
                        if (props.authentication) {
                           navigate('/info');
                        } else {
                           navigate('/login');
                        }
                        setIsSidePanelOpen(false);
                     }}
                     style={{ cursor: 'pointer' }}
                  >
                     <CardHeader
                        header={
                           <div className={classes.layoutSidePenalCardHeader}>
                              <Avatar
                                 active={props.authentication ? 'active' : 'unset'}
                                 name={props.authentication ? props.authentication?.user.name : undefined}
                              />
                              <Text style={{ fontWeight: tokens.fontWeightSemibold }}>
                                 {props.authentication ? props.authentication.user.name : '로그인해주세요'}
                              </Text>
                           </div>
                        }
                     />
                     {props.authentication && (
                        <CardFooter style={{ width: '100%' }}>
                           <div className={classes.layoutSidePenalCardFooter}>
                              <Text>{props.authentication.user.mail}</Text>
                           </div>
                        </CardFooter>
                     )}
                  </Card>
               </div>
               <div className={classes.layoutSidePenalWrapper}>
                  {menuList.map((menu, index) => {
                     return (
                        <Button
                           className={
                              location.pathname === menu.url
                                 ? mergeClasses(classes.layoutSidePenalItemRoot, classes.layoutSidePenalItemSelect)
                                 : classes.layoutSidePenalItemRoot
                           }
                           appearance="subtle"
                           key={`${menu.key}_${index}_nav`}
                           onClick={() => {
                              navigate(menu.url);
                              setIsSidePanelOpen(false);
                           }}
                        >
                           {menu.name}
                        </Button>
                     );
                  })}
               </div>
            </DrawerBody>
            <DrawerFooter>
               <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {props.authentication?.user.roles?.findIndex((element) => element.name === RoleName.Admin)! > -1 && (
                     <Button
                        appearance="transparent"
                        icon={<ArchiveMultipleFilled />}
                        onClick={() => {
                           navigate('/admin');
                           setIsSidePanelOpen(false);
                        }}
                     >
                        관리자
                     </Button>
                  )}
                  {props.authentication && (
                     <Button
                        appearance="transparent"
                        style={{ color: tokens.colorBrandBackground }}
                        onClick={() => setIsQuitSiteDialog(true)}
                     >
                        회원 탈퇴
                     </Button>
                  )}
               </div>
            </DrawerFooter>
         </Drawer>

         <div className={classes.layoutContentWrapper}>
            <Routes>
               <Route path="*" element={props.authentication ? <Navigate to={''} /> : <Navigate to={'/login'} />} />
               <Route path="" element={<Home />} />
               {props.authentication ? (
                  <>
                     <Route path="/word" element={<Word />} />
                     <Route path="/sentence" element={<Sentence />} />
                     <Route path="/ai" element={<Ai />} />
                     <Route path="/chat" element={<>working...</>} />
                     <Route path="/info" element={<MyInfo />} />
                     {props.authentication.user.roles?.findIndex((element) => element.name === RoleName.Admin)! > -1 && (
                        <Route path="/admin" element={<Admin />} />
                     )}
                  </>
               ) : (
                  <>
                     <Route path="/signup" element={<SignUp />} />
                     <Route path="/login" element={<Login />} />
                     <Route path="/findpassword" element={<FindPassword />} />
                  </>
               )}
            </Routes>
          
            {props.authentication && location.pathname !== '/ai' && (
               <div style={{ position: 'fixed', bottom: 40, right: 50 }}>
                  <div style={{ display: visibleChat ? 'block' : 'none' }}>
                     <AiSmallChat onDismiss={() => setVisibleChat(false)} isOpen={visibleChat} />
                  </div>
                  <Avatar
                     size={48}
                     color="brand"
                     name="A I"
                     active={isAIHover ? 'active' : 'inactive'}
                     onMouseOver={() => setIsAIHover(true)}
                     onMouseLeave={() => setIsAIHover(false)}
                     style={{ cursor: 'pointer' }}
                     onClick={() => setVisibleChat(!visibleChat)}
                  />
               </div>
            )}
         </div>
         <ConfirmDialog
            isOpen={isQuitSiteDialog}
            title="탈퇴"
            subText="정말로 탈퇴 하시겠습니까?"
            onDismiss={() => setIsQuitSiteDialog(false)}
            onSuccess={onQuitSite}
            isLoding={isActionLoding}
         />
         <ToastContainer style={{ zIndex: 10000000 }} />
      </div>
   );
};
