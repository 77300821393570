import { IAuthentication } from '../components/common/types';
import { IWord, WordSearchColumn } from '../components/word/types';
import { httpGet, httpPost, httpPut } from './common';
import { ApiResult } from './types';

export const getWordList = async (
   authentication: IAuthentication,
   searchText: string,
   searchColumn: WordSearchColumn,
   currentPageNum: number
): Promise<ApiResult> => {
   try {
      return await httpGet(`word/list/${searchColumn}/${currentPageNum}?searchText=${searchText}`, authentication);
   } catch (err) {
      throw err;
   }
};

export const getWordCount = async (authentication: IAuthentication): Promise<ApiResult> => {
   try {
      return await httpGet('word/count', authentication);
   } catch (err) {
      throw err;
   }
};

export const saveWord = async (authentication: IAuthentication, word: IWord): Promise<ApiResult> => {
   try {
      return await httpPost('word', word, authentication);
   } catch (err) {
      throw err;
   }
};

export const updateWord = async (authentication: IAuthentication, word: IWord): Promise<ApiResult> => {
   try { 
      return await httpPut('word', word, authentication);
   } catch (err) {
      throw err;
   }
}

export const deleteWord = async (authentication:IAuthentication, words:IWord[]):Promise<ApiResult> => {
   try {
      return await httpPut('word/list', words, authentication);
   } catch (err) {
      throw err;
   }
}

export const enWordIsOverlap = async (authentication:IAuthentication, enWord:string):Promise<ApiResult> => {
   try {
      return await httpGet(`word/isOverlap/${enWord}`, authentication);
   } catch (err) {
      throw err;
   }
}

export const recentlyWord = async (authentication:IAuthentication):Promise<ApiResult> => {
   try {
      return await httpGet('word/recently',authentication);
   } catch (err) {
      throw err;
   }
}