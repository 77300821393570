import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useAdminStyles = makeStyles({
   card: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      boxShadow: tokens.shadow4,
      ...shorthands.borderRadius('5px'),
      ...shorthands.padding('10px'),
      ...shorthands.gap('5px'),
   },
   cardRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
   },
   cardTitle: {
      display: 'flex',
      alignItems: 'center',
      ...shorthands.gap('5px'),
      ...shorthands.padding('10px', '5px'),
      ...shorthands.borderBottom('1px', 'solid', '#e0e0e0'),
   },
   cardContent: {
      width: '50%',
      ...shorthands.padding('10px'),
   },
   toolsButton: {
      minWidth: 0,
      ...shorthands.padding('0px'),
   },
   hoverRed: {
      ':hover': {
         color: 'red',
      },
   },
   hoverDarkRed: {
      ':hover': {
         color: 'darkred',
      },
   },
});
