import { useContext, useEffect, useState } from 'react';
import { IUser } from '../../common/types';
import { UserSearchType } from '../types';
import { getUsers, userActive, userDelete, userDisabled } from '../../../services/admin';
import { AuthenticationContext } from '../../../contexts/context';
import {
   Button,
   DataGrid,
   DataGridBody,
   DataGridCell,
   DataGridHeader,
   DataGridHeaderCell,
   DataGridRow,
   Dropdown,
   Input,
   Option,
   TableCellLayout,
   TableColumnDefinition,
   TableRowId,
   createTableColumn,
   mergeClasses,
} from '@fluentui/react-components';
import { RecordStopRegular, DeleteRegular, PlayCircleRegular, SearchRegular } from '@fluentui/react-icons';
import { Pagination } from '../../common/controls/Pagination';
import { useAdminStyles } from '../styles';
import { ConfirmDialog } from '../../common/controls/ConfirmDialog';

const dropdownOptions = [
   { key: UserSearchType.UserId, text: '아이디' },
   { key: UserSearchType.Name, text: '이름' },
   { key: UserSearchType.Mail, text: '메일' },
];

export const UserManage: React.FC = () => {
   const { authentication } = useContext(AuthenticationContext);
   const classes = useAdminStyles();
   const [users, setUsers] = useState<IUser[]>([]);
   const [searchType, setSearchType] = useState<UserSearchType>(UserSearchType.UserId);
   const [currentPage, setCurrentPage] = useState<number>(1);
   const [totalPage, setTotalPage] = useState<number>(0);
   const [searchText, setSearchText] = useState<string>('');
   const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
   const [selectedUserIndexes, setSelectedUserIndexes] = useState<number[]>([]);
   const [isActiveDialog, setIsActiveDialog] = useState<boolean>(false);
   const [isDisableDialog, setIsDisableDialog] = useState<boolean>(false);
   const [isDeleteDialog, setIsDeleteDialog] = useState<boolean>(false);
   const [isActionLoding, setIsActionLoding] = useState<boolean>(false);

   useEffect(() => {
      getUsersAsnyc();
   }, []);

   const getUsersAsnyc = async () => {
      const userRes = await getUsers(authentication!, searchType, currentPage, searchText);
      if (userRes.isSuccess) {
         setTotalPage(userRes.data.totalPage);
         let newUsers: IUser[] = [];
         userRes.data.users.forEach((user: any) => {
            const newUser: IUser = {
               mail: user.mail,
               name: user.name,
               userId: user.userId,
               isDeleted: user.isDeleted,
               roles: user.roles,
            };
            newUsers.push(newUser);
         });
         setUsers(newUsers);
      }
   };

   const onActiveUsers = async () => {
      setIsActionLoding(true);
      let activeUsers: IUser[] = [...selectedUsers];
      activeUsers = activeUsers.filter((element) => element.isDeleted === 'Y');
      const result = await userActive(authentication!, activeUsers);
      if (result.isSuccess) {
         let newUsers: IUser[] = [...users];
         activeUsers.forEach((user) => {
            let activeUserIndex = newUsers.findIndex((element) => element.userId === user.userId);
            if (activeUserIndex > -1) {
               newUsers.splice(activeUserIndex, 1, { ...user, isDeleted: 'N' });
            }
         });
         setUsers(newUsers);
         setSelectedUserIndexes([]);
         setSelectedUsers([]);
      }
      setIsActiveDialog(false);
      setIsActionLoding(false);
   };

   const onDisableUsers = async () => {
      setIsActionLoding(true);
      let disableUsers: IUser[] = [...selectedUsers];
      disableUsers = disableUsers.filter((element) => element.isDeleted === 'N');
      const result = await userDisabled(authentication!, disableUsers);
      if (result.isSuccess) {
         let newUsers: IUser[] = [...users];
         disableUsers.forEach((user) => {
            let disableUserIndex = newUsers.findIndex((element) => element.userId === user.userId);
            if (disableUserIndex > -1) {
               newUsers.splice(disableUserIndex, 1, { ...user, isDeleted: 'Y' });
            }
         });
         setUsers(newUsers);
         setSelectedUserIndexes([]);
         setSelectedUsers([]);
      }
      setIsDisableDialog(false);
      setIsActionLoding(false);
   };

   const onDeleteUsers = async () => {
      setIsActionLoding(true);
      const result = await userDelete(authentication!, selectedUsers);
      if (result.isSuccess) {
         getUsersAsnyc();
      }
      setSelectedUserIndexes([]);
      setSelectedUsers([]);
      setIsDeleteDialog(false);
      setIsActionLoding(false);
   };

   const columns: TableColumnDefinition<IUser>[] = [
      createTableColumn<IUser>({
         columnId: 'index',
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>순번</div>;
         },
         renderCell: (item) => {
            return (
               <TableCellLayout style={{ justifyContent: 'center' }}>
                  {users.findIndex((element) => element.userId === item.userId) + 1}
               </TableCellLayout>
            );
         },
      }),
      createTableColumn<IUser>({
         columnId: 'userId',
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>유저아이디</div>;
         },
         renderCell: (item) => {
            return <TableCellLayout style={{ justifyContent: 'center' }}>{item.userId}</TableCellLayout>;
         },
      }),
      createTableColumn<IUser>({
         columnId: 'name',
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>이름</div>;
         },
         renderCell: (item) => {
            return <TableCellLayout style={{ justifyContent: 'center' }}>{item.name}</TableCellLayout>;
         },
      }),
      createTableColumn<IUser>({
         columnId: 'mail',
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>메일</div>;
         },
         renderCell: (item) => {
            return <TableCellLayout style={{ justifyContent: 'center' }}>{item.mail}</TableCellLayout>;
         },
      }),
      createTableColumn<IUser>({
         columnId: 'roles',
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>권한</div>;
         },
         renderCell: (item) => {
            return (
               <TableCellLayout style={{ justifyContent: 'center' }}>
                  {item.roles?.map((value, index) => {
                     let text = value.name === 'ROLE_USER' ? '유저' : '관리자';

                     if (item.roles?.length! - 1 === index) return text;
                     else return `${text}, `;
                  })}
               </TableCellLayout>
            );
         },
      }),
      createTableColumn<IUser>({
         columnId: 'isDeleted',
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>비 활성화</div>;
         },
         renderCell: (item) => {
            return <TableCellLayout style={{ justifyContent: 'center' }}>{item.isDeleted}</TableCellLayout>;
         },
      }),
   ];

   const columnSizingOptions = {
      index: {
         defaultWidth: 30,
         minWidth: 30,
      },
      userId: {
         defaultWidth: 150,
         minWidth: 150,
      },
      name: {
         defaultWidth: 200,
         minWidth: 200,
      },
      mail: {
         defaultWidth: 250,
         minWidth: 250,
      },
      roles: {
         defaultWidth: 200,
         minWidth: 200,
      },
      isDeleted: {
         defaultWidth: 30,
         minWidth: 30,
      },
   };

   return (
      <div>
         <div style={{ display: 'flex', justifyContent: 'space-between', padding: 10 }}>
            <div style={{ display: 'flex', gap: 10 }}>
               <Button
                  className={classes.toolsButton}
                  appearance="transparent"
                  icon={<PlayCircleRegular />}
                  disabled={selectedUserIndexes.length === 0 || selectedUsers.findIndex((element) => element.isDeleted === 'Y') === -1}
                  onClick={() => setIsActiveDialog(true)}
               >
                  활성화
               </Button>
               <Button
                  className={mergeClasses(classes.toolsButton, classes.hoverDarkRed)}
                  appearance="transparent"
                  icon={<RecordStopRegular />}
                  disabled={selectedUserIndexes.length === 0 || selectedUsers.findIndex((element) => element.isDeleted === 'N') === -1}
                  onClick={() => setIsDisableDialog(true)}
               >
                  비활성화
               </Button>
               <Button
                  className={mergeClasses(classes.toolsButton, classes.hoverRed)}
                  appearance="transparent"
                  icon={<DeleteRegular />}
                  disabled={selectedUserIndexes.length === 0}
                  onClick={() => setIsDeleteDialog(true)}
               >
                  영구 삭제
               </Button>
            </div>
            <div style={{ display: 'flex', gap: 5 }}>
               <Dropdown
                  style={{ minWidth: 0 }}
                  value={dropdownOptions.find((element) => element.key === searchType)?.text}
                  selectedOptions={[searchType]}
                  onOptionSelect={(event, data) => setSearchType(data.optionValue as UserSearchType)}
               >
                  {dropdownOptions.map((value, index) => (
                     <Option key={value.key} value={value.key}>
                        {value.text}
                     </Option>
                  ))}
               </Dropdown>
               <Input value={searchText} onChange={(event, data) => setSearchText(data.value)} />
               <Button appearance="primary" icon={<SearchRegular />}>
                  검색
               </Button>
            </div>
         </div>
         <div style={{ cursor: 'pointer' }}>
            <DataGrid
               items={users}
               columns={columns}
               columnSizingOptions={columnSizingOptions}
               selectionMode="multiselect"
               selectedItems={selectedUserIndexes}
               onSelectionChange={(event, data) => {
                  const set = new Set<TableRowId>(data.selectedItems);
                  const indexes = Array.from(set) as number[];
                  let newSelectedUsers: IUser[] = [];
                  indexes.forEach((index) => {
                     newSelectedUsers.push(users[index]);
                  });
                  setSelectedUserIndexes(indexes);
                  setSelectedUsers(newSelectedUsers);
               }}
               size="small"
               sortable
               resizableColumns
            >
               <DataGridHeader>
                  <DataGridRow style={{ textAlign: 'center' }}>
                     {({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}
                  </DataGridRow>
               </DataGridHeader>
               <DataGridBody>
                  {({ item, rowId }) => (
                     <DataGridRow key={rowId}>{({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}</DataGridRow>
                  )}
               </DataGridBody>
            </DataGrid>
         </div>
         <Pagination currentPage={currentPage} totalPage={totalPage} onChangeCurrentPage={setCurrentPage} />
         <ConfirmDialog
            isOpen={isActiveDialog}
            title="유저 활성화"
            subText="선택하신 유저들을 활성화 시키시겠습니까?"
            onSuccess={onActiveUsers}
            onDismiss={() => setIsActiveDialog(false)}
            isLoding={isActionLoding}
         />
         <ConfirmDialog
            isOpen={isDisableDialog}
            title="유저 비 활성화"
            subText="선택하신 유저들을 비 활성화 시키시겠습니까?"
            onSuccess={onDisableUsers}
            onDismiss={() => setIsDisableDialog(false)}
            isLoding={isActionLoding}
         />
         <ConfirmDialog
            isOpen={isDeleteDialog}
            title="유저 영구 삭제"
            subText="선택하신 유저들을 영구삭제 시키시겠습니까?"
            onSuccess={onDeleteUsers}
            onDismiss={() => setIsDeleteDialog(false)}
            isLoding={isActionLoding}
         />
      </div>
   );
};
