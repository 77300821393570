import { MemorizeType } from "../common/types";

export const enum SentenceSearchColumn {
   EnSentence = 'enSentence',
   KrSentence = 'krSentence',
   Remarks = 'remarks',
}

export const enum SentenceEditorType {
   Add = 'add',
   Update = 'update'
}

export interface ISentence {
   sentenceCode?: number;
   userId: string;
   enSentence: string;
   krSentence: string;
   createDate: string;
   isMemorize?: MemorizeType;
   remarks?: string;
}

export interface ISentenceCount {
   totalSentence: number;
   memorizeSentence: number;
}