import { useMemo } from 'react';
import { useCommonStyles } from '../styles';
import { Button, mergeClasses } from '@fluentui/react-components';
import { ChevronLeftFilled, ChevronRightFilled } from '@fluentui/react-icons';

interface PPagination {
   currentPage: number;
   totalPage: number;
   onChangeCurrentPage: (currentPage: number) => void;
}

export const Pagination: React.FC<PPagination> = (props) => {
   const classes = useCommonStyles();

   return (
      <div className={classes.paginationWrapper}>
         <Button
            className={classes.paginationButtonRoot}
            icon={<ChevronLeftFilled />}
            appearance="subtle"
            disabled={props.currentPage === 1}
            onClick={() => props.onChangeCurrentPage(props.currentPage - 1)}
         />
         <div>
            {
               useMemo(() => {
                  let totalPageNums: number[] = [];
                  for (let i = 1; i < props.totalPage + 1; ++i) {
                     totalPageNums.push(i);
                  }
                  return totalPageNums.map((value) => (
                     <Button
                        key={`${value}_pageNum`}
                        className={
                           props.currentPage === value
                              ? mergeClasses(classes.paginationButtonRoot, classes.paginationButtonSelected)
                              : classes.paginationButtonRoot
                        }
                        appearance={props.currentPage === value ? "primary" :"subtle"}
                        onClick={() => props.onChangeCurrentPage(value)}
                     >
                        {value}
                     </Button>
                  ));
               }, [props.totalPage, props.currentPage]) // eslint-disable-line react-hooks/exhaustive-deps
            }
         </div>
         <Button
            className={classes.paginationButtonRoot}
            icon={<ChevronRightFilled />}
            appearance="subtle"
            disabled={props.currentPage === props.totalPage}
            onClick={() => props.onChangeCurrentPage(props.currentPage + 1)}
         />
      </div>
   );
};
