import { Button, Field, Input, InputOnChangeData, Spinner, Text } from '@fluentui/react-components';
import { useCommonStyles } from './styles';
import { useEffect, useRef, useState } from 'react';
import { checkMailCode, checkUserId, getVerificationMail, userSignUp } from '../../services/user';
import { infoNotification, successNotification, warningNotification } from '../../utils/notification.utils';
import { ValidationStateType } from './types';
import { useNavigate } from 'react-router-dom';

export const SignUp: React.FC = () => {
   const classes = useCommonStyles();
   const [isSendVerification, setIsSendVerification] = useState<boolean>(false);
   const [isSendMail, setIsSendMail] = useState<boolean>(false);
   const [mail, setMail] = useState<string>('');
   const [mailCode, setMailCode] = useState<string>('');
   const [userId, setUserId] = useState<string>('');
   const [password, setPassword] = useState<string>('');
   const [rePassword, setRePassword] = useState<string>('');
   const [verificationMail, setVerificationMail] = useState<boolean>(false);
   const [checkedUserId, setCheckedUserId] = useState<boolean>(false);
   const [mailVaildationState, setMailVaildationState] = useState<ValidationStateType>('none');
   const [mailValidationMessage, setMailValidationMessage] = useState<string>();
   const [userName, setUserName] = useState<string>('');
   const passwordInputRef = useRef<HTMLInputElement>(null);
   const rePasswordInputRef = useRef<HTMLInputElement>(null);
   const mailInputRef = useRef<HTMLInputElement>(null);
   const navigate = useNavigate();

   useEffect(() => {
      if (mailCode) {
         checkMailCode(mail, mailCode).then((response) => {
            if (response.isSuccess) {
               setVerificationMail(response.data);
               if (response.data) {
                  setIsSendMail(true);
                  setMailVaildationState('success');
                  setMailValidationMessage('코드가 일치합니다.');
               } else {
                  setMailVaildationState('error');
                  setMailValidationMessage('코드가 일치하지 않습니다.');
               }
            }
         });
      }
   }, [mailCode]);

   const onChangeUserId = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      const onlyEnglish = /[^A-Za-z0-9]/g;

      if (onlyEnglish.test(data.value)) {
         warningNotification('아이디는 한글 또는 특수문자가 포함될 수 없습니다.');
      }
      setUserId(data.value.replace(onlyEnglish, ''));
   };

   const onClickUserId = async () => {
      if (userId) {
         const result = await checkUserId(userId);
         if (result.isSuccess) {
            if (result.data) {
               setCheckedUserId(true);
               successNotification('등록 가능한 아이디 입니다.');
            } else {
               warningNotification('이미 등록된 아이디 입니다.');
            }
         }
      } else {
      }
   };

   const onClickMailVerification = async () => {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (mail && emailPattern.test(mail)) {
         setIsSendMail(true);
         infoNotification('메일을 발송 중입니다. 잠시만 기다려 주세요!');
         const result = await getVerificationMail(mail);
         if (result.isSuccess) {
            if (result.data) {
               successNotification('메일 발송이 완료 되었습니다. 이메일을 확인해주세요');
               setIsSendMail(false);
               setIsSendVerification(true);
            } else {
               warningNotification('이미 등록된 메일입니다.');
               mailInputRef.current?.focus();
               setIsSendMail(false);
               setIsSendVerification(false);
            }
         }
      } else {
         warningNotification('유효하진 메일 형식입니다.');
         mailInputRef.current?.focus();
      }
   };

   const onChangeUserName = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      const regex = /^[^0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/;
      if (!regex.test(data.value)) {
         warningNotification('이름에 숫자나 특수문자를 사용할 수 없습니다.');
      } else {
         setUserName(data.value);
      }
   };

   const onClickSignUp = async () => {
      if (rePassword !== password) {
         warningNotification('비밀번호와 비밀번호 확인이 같지 않습니다.');
         rePasswordInputRef.current?.focus();
      } else if (!checkStrongPassword(password)) {
         warningNotification('비밀번호는 특수문자 1개 이상, 대문자 1개 이상, 소문자 1개 이상, 8자리 이상으로 입력해 주세요');
         passwordInputRef.current?.focus();
      } else {
         const result = await userSignUp({ mail: mail, name: userName, userId: userId, password: password });
         if (result.isSuccess) {
            navigate('/login');
         }
      }
   };

   const checkStrongPassword = (password: string) => {
      const hasSpecialChar = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(password);
      const hasUppercase = /[A-Z]/.test(password);
      const hasLowercase = /[a-z]/.test(password);
      const hasEightChar = password.length > 7;
      return hasSpecialChar && hasUppercase && hasEightChar && hasLowercase;
   };

   return (
      <div style={{ width: '100%', height: '100%', padding: 10, boxSizing: 'border-box' }}>
         <div
            style={{
               display: 'flex',
               flexDirection: 'column',
               justifyContent: 'center',
               alignItems: 'center',
               padding: 20,
               height: '100%',
               width: '100%',
               boxSizing: 'border-box',
            }}
         >
            <Text weight="semibold" size={700}>
               회원가입
            </Text>
            <div
               style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                  marginTop: 40,
               }}
            >
               <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                  <div style={{ display: 'flex', gap: 5 }}>
                     <Input
                        placeholder="아이디"
                        value={userId}
                        style={{ width: '200px' }}
                        onChange={onChangeUserId}
                        disabled={checkedUserId}
                     />
                     <Button appearance="primary" onClick={onClickUserId} disabled={checkedUserId || !userId}>
                        중복여부
                     </Button>
                  </div>
                  <Input value={userName} placeholder="이름" onChange={onChangeUserName} style={{ width: '200px' }} />
                  <Input
                     ref={passwordInputRef}
                     type="password"
                     placeholder="비밀번호"
                     style={{ width: '200px' }}
                     onChange={(event, data) => setPassword(data.value)}
                  />
                  <Input
                     ref={rePasswordInputRef}
                     type="password"
                     placeholder="비밀번호 확인"
                     style={{ width: '200px' }}
                     onChange={(event, data) => setRePassword(data.value)}
                  />
                  <div style={{ display: 'flex', gap: 5 }}>
                     <Input
                        ref={mailInputRef}
                        type="email"
                        value={mail}
                        placeholder="이메일"
                        style={{ width: '200px' }}
                        onChange={(event, data) => setMail(data.value)}
                        disabled={isSendMail}
                     />
                     <Button appearance="primary" onClick={onClickMailVerification} disabled={mail.length === 0 || isSendMail}>
                        이메일 인증
                     </Button>
                  </div>
                  {isSendMail && !isSendVerification ? (
                     <Spinner size="tiny" />
                  ) : (
                     <>
                        {isSendVerification && (
                           <Field validationState={mailVaildationState} validationMessage={mailValidationMessage}>
                              <Input placeholder="코드를 입력해 주세요" onChange={(event, data) => setMailCode(data.value)} />
                           </Field>
                        )}
                     </>
                  )}
                  <Button
                     appearance="primary"
                     onClick={onClickSignUp}
                     disabled={!verificationMail || !checkedUserId || !password || !rePassword}
                  >
                     회원가입
                  </Button>
               </div>
            </div>
         </div>
      </div>
   );
};
