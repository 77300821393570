import { ISchedule, UserSearchType } from '../components/admin/types';
import { IAuthentication, IUser } from '../components/common/types';
import { httpGet, httpPost, httpPut } from './common';
import { ApiResult } from './types';

export const getTokenCountList = async (authentication: IAuthentication, count: number): Promise<ApiResult> => {
   try {
      return await httpGet(`admin/tokens/count/list?count=${count}`, authentication);
   } catch (err) {
      throw err;
   }
};

export const getTokenCount = async (authentication: IAuthentication): Promise<ApiResult> => {
   try {
      return await httpGet('admin/tokens/count', authentication);
   } catch (err) {
      throw err;
   }
};

export const getTokenCountByDate = async (authentication: IAuthentication, startDate: string, endDate: string): Promise<ApiResult> => {
   try {
      return await httpGet(`admin/tokens/count/${startDate}/${endDate}`, authentication);
   } catch (err) {
      throw err;
   }
};

export const getUsers = async (
   authentication: IAuthentication,
   searchType: UserSearchType,
   currentPage: number,
   searchText: string
): Promise<ApiResult> => {
   try {
      return await httpGet(`admin/user/list/${searchType}/${currentPage}?searchText=${searchText}`, authentication);
   } catch (err) {
      throw err;
   }
};

export const userDisabled = async (authentication: IAuthentication, users: IUser[]): Promise<ApiResult> => {
   try {
      return await httpPost('admin/user/list/disable', users, authentication);
   } catch (err) {
      throw err;
   }
};

export const userActive = async (authentication: IAuthentication, users: IUser[]): Promise<ApiResult> => {
   try {
      return await httpPost('admin/user/list/active', users, authentication);
   } catch (err) {
      throw err;
   }
};

export const userDelete = async (authentication: IAuthentication, users: IUser[]): Promise<ApiResult> => {
   try {
      return await httpPut('admin/user/list', users, authentication);
   } catch (err) {
      throw err;
   }
};

export const getSchedule = async (authentication: IAuthentication): Promise<ApiResult> => {
   try {
      return await httpGet('admin/schedule', authentication);
   } catch (err) {
      throw err;
   }
};

export const wordScheduleStart = async (authentication: IAuthentication, schedule: ISchedule): Promise<ApiResult> => {
   try {
      return await httpPost('admin/schedule/word', schedule, authentication);
   } catch (err) {
      throw err;
   }
};

export const sentenceScheduleStart = async (authentication: IAuthentication, schedule: ISchedule): Promise<ApiResult> => {
   try {
      return await httpPost('admin/schedule/sentence', schedule, authentication);
   } catch (err) {
      throw err;
   }
};

export const wordScheduleStop = async (authentication: IAuthentication): Promise<ApiResult> => {
   try {
      return await httpPost('admin/schedule/stop/word', undefined, authentication);
   } catch (err) {
      throw err;
   }
};

export const sentenceScheduleStop = async (authentication: IAuthentication): Promise<ApiResult> => {
   try {
      return await httpPost('admin/schedule/stop/sentence', undefined, authentication);
   } catch (err) {
      throw err;
   }
};

export const getRecommendLog = async (
   authentication: IAuthentication,
   startDate: string,
   endDate: string,
   currentPage: number,
   count: number
): Promise<ApiResult> => {
   try {
      return await httpGet(`admin/recommend/log/${startDate}/${endDate}?currentPage=${currentPage}&count=${count}`,authentication);
   } catch (err) {
      throw err;
   }
};
