import { useContext, useEffect, useState } from 'react';
import { ISentence, SentenceEditorType } from '../types';
import { AuthenticationContext } from '../../../contexts/context';
import { MemorizeType } from '../../common/types';
import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle } from '@fluentui/react-components/unstable';
import { DismissRegular } from '@fluentui/react-icons';
import { Button, Dropdown, Field, Input, Option, Textarea } from '@fluentui/react-components';
import dayjs from 'dayjs';
import { saveSentence, updateSentence } from '../../../services/sentence';

interface PSentenceEditor {
   sentence?: ISentence;
   isOpen?: boolean;
   type?: SentenceEditorType;
   onDismiss: () => void;
   onChangeSentence: (sentence: ISentence) => void;
}

export const SentenceEditor: React.FC<PSentenceEditor> = (props) => {
   const { authentication } = useContext(AuthenticationContext);
   const [sentence, setSentence] = useState<ISentence>();
   const [enSentence, setEnSentence] = useState<string>('');
   const [krSentence, setKrSentence] = useState<string>('');
   const [isMemorize, setIsMemorize] = useState<MemorizeType>(MemorizeType.N);
   const [remarks, setRemarks] = useState<string>('');

   useEffect(() => {
      if (!props.isOpen) {
         resetState();
      }
   }, [props.isOpen]);

   useEffect(() => {
      switch (props.type) {
         case SentenceEditorType.Add:
            resetState();
            break;
         case SentenceEditorType.Update:
            if (props.sentence) {
               setSentence(props.sentence);
               setEnSentence(props.sentence.enSentence);
               setKrSentence(props.sentence.krSentence);
               setRemarks(props.sentence.remarks!);
               setIsMemorize(props.sentence.isMemorize!);
            }
            break;
      }
   }, [props.type]); // eslint-disable-line react-hooks/exhaustive-deps

   const resetState = () => {
      setSentence(undefined);
      setEnSentence('');
      setKrSentence('');
      setIsMemorize(MemorizeType.N);
      setRemarks('');
   };

   const isDisabled = (): boolean => {
      let result = true;
      if (enSentence !== '' && krSentence !== '' && isMemorize) {
         return false;
      }
      return result;
   };

   const onClickSave = async () => {
      let result;
      let newSentence: ISentence;
      switch (props.type) {
         case SentenceEditorType.Add:
            newSentence = {
               enSentence: enSentence,
               krSentence: krSentence,
               remarks: remarks,
               isMemorize: isMemorize,
               userId: authentication?.user.userId!,
               createDate: dayjs().format('YYYY-MM-DD'),
            };
            result = await saveSentence(authentication!, newSentence);
            break;
         case SentenceEditorType.Update:
            newSentence = {
               enSentence: enSentence,
               krSentence: krSentence,
               remarks: remarks,
               isMemorize: isMemorize,
               userId: authentication?.user.userId!,
               createDate: sentence?.createDate!,
               sentenceCode: sentence?.sentenceCode,
            };
            result = await updateSentence(authentication!, newSentence);
            break;
      }
      if (result?.isSuccess) {
         props.onChangeSentence(result.data);
      }
   };

   return (
      <Drawer
         type="overlay"
         open={props.isOpen}
         position="end"
         style={{ minWidth: 300 }}
         onKeyDown={(data) => {
            if (data.code === 'Escape') props.onDismiss();
         }}
      >
         <DrawerHeader>
            <DrawerHeaderTitle action={<Button appearance="transparent" icon={<DismissRegular />} onClick={props.onDismiss} />}>
               {props.type === SentenceEditorType.Update ? '수정' : '등록'}
            </DrawerHeaderTitle>
         </DrawerHeader>
         <DrawerBody>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingTop: 20 }}>
               <Field label={'영문장'} required>
                  <Input placeholder="영문장" value={enSentence} onChange={(event, data) => setEnSentence(data.value)} autoFocus />
               </Field>
               <Field label={'번역'} required>
                  <Input placeholder="번역" value={krSentence} onChange={(event, data) => setKrSentence(data.value)} />
               </Field>
               <Field label={'암기 여부'} required>
                  <Dropdown value={isMemorize} onOptionSelect={(event, data) => setIsMemorize(data.optionValue as MemorizeType)}>
                     <Option key={MemorizeType.N} value={MemorizeType.N}>
                        {MemorizeType.N}
                     </Option>
                     <Option key={MemorizeType.Y} value={MemorizeType.Y}>
                        {MemorizeType.Y}
                     </Option>
                  </Dropdown>
               </Field>
               <Field label={'비고'}>
                  <Textarea value={remarks} onChange={(event, data) => setRemarks(data.value)} />
               </Field>
            </div>
         </DrawerBody>
         <DrawerFooter>
            <Button appearance="primary" onClick={onClickSave} disabled={isDisabled()}>
               저장
            </Button>
            <Button onClick={props.onDismiss}>닫기</Button>
         </DrawerFooter>
      </Drawer>
   );
};
