import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerHeaderTitle } from '@fluentui/react-components/unstable';
import { IWord, WordEditorType } from '../types';
import { useContext, useEffect, useState } from 'react';
import { Button, Dropdown, Field, Input, Option, Textarea } from '@fluentui/react-components';
import { DismissRegular } from '@fluentui/react-icons';
import { MemorizeType } from '../../common/types';
import { AuthenticationContext } from '../../../contexts/context';
import dayjs from 'dayjs';
import { enWordIsOverlap, saveWord, updateWord } from '../../../services/word';

interface PWordEditor {
   word?: IWord;
   isOpen: boolean;
   type?: WordEditorType;
   onDismiss: () => void;
   onChangeWord: (word: IWord) => void;
}

export const WordEditor: React.FC<PWordEditor> = (props) => {
   const { authentication } = useContext(AuthenticationContext);
   const [word, setWord] = useState<IWord>();
   const [enWord, setEnWord] = useState<string>('');
   const [krWord, setKrWord] = useState<string>('');
   const [isMemorize, setIsMemorize] = useState<MemorizeType>(MemorizeType.N);
   const [remarks, setRemarks] = useState<string>('');
   const [isOverlapEnWord, setIsOverlapEnWord] = useState<boolean>(false);

   useEffect(() => {
      if (!props.isOpen) {
         resetState();
      }
   }, [props.isOpen]);

   useEffect(() => {
      switch (props.type) {
         case WordEditorType.Add:
            resetState();
            break;
         case WordEditorType.Update:
            if (props.word) {
               setWord(props.word);
               setEnWord(props.word.enWord);
               setKrWord(props.word.krWord);
               setRemarks(props.word.remarks);
               setIsMemorize(props.word.isMemorize!);
            }
            break;
      }
   }, [props.type]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (enWord !== '' && props.word?.enWord !== enWord) {
         enWordIsOverlap(authentication!, enWord).then((response) => {
            if (response.isSuccess) {
               setIsOverlapEnWord(response.data);
            }
         });
      }
   }, [enWord]); // eslint-disable-line react-hooks/exhaustive-deps

   const isDisabled = (): boolean => {
      let result: boolean = true;

      if (enWord !== '' && krWord !== '' && isMemorize && isOverlapEnWord === false) {
         result = false;
      }

      return result;
   };

   const resetState = () => {
      setWord(undefined);
      setEnWord('');
      setKrWord('');
      setRemarks('');
      setIsMemorize(MemorizeType.N);
   };

   const onClickSave = async () => {
      let result;
      let newWord: IWord;

      switch (props.type) {
         case WordEditorType.Add:
            newWord = {
               enWord: enWord,
               krWord: krWord,
               remarks: remarks,
               isMemorize: isMemorize,
               userId: authentication?.user.userId!,
               createDate: dayjs().format('YYYY-MM-DD'),
            };
            result = await saveWord(authentication!, newWord);
            break;
         case WordEditorType.Update:
            newWord = {
               enWord: enWord,
               krWord: krWord,
               remarks: remarks,
               isMemorize: isMemorize,
               userId: authentication?.user.userId!,
               createDate: word?.createDate!,
               wordCode: word?.wordCode,
            };
            result = await updateWord(authentication!, newWord);
            break;
      }
      if (result?.isSuccess) {
         props.onChangeWord(result.data);
      }
   };

   return (
      <Drawer
         type="overlay"
         open={props.isOpen}
         position="end"
         style={{ minWidth: 300 }}
         onKeyDown={(data) => {
            if (data.code === 'Escape') props.onDismiss();
         }}
      >
         <DrawerHeader>
            <DrawerHeaderTitle action={<Button appearance="transparent" icon={<DismissRegular />} onClick={props.onDismiss} />}>
               {props.type === WordEditorType.Update ? '수정' : '등록'}
            </DrawerHeaderTitle>
         </DrawerHeader>
         <DrawerBody>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', paddingTop: 20 }}>
               <Field label={'영단어'} validationMessage={isOverlapEnWord ? '이미 등록된 영단어 입니다.' : undefined} required>
                  <Input placeholder="영단어" value={enWord} onChange={(event, data) => setEnWord(data.value)} autoFocus />
               </Field>
               <Field label={'뜻'} required>
                  <Input placeholder="뜻" value={krWord} onChange={(event, data) => setKrWord(data.value)} />
               </Field>
               <Field label={'암기 여부'} required>
                  <Dropdown value={isMemorize} onOptionSelect={(event, data) => setIsMemorize(data.optionValue as MemorizeType)}>
                     <Option key={MemorizeType.N} value={MemorizeType.N}>
                        {MemorizeType.N}
                     </Option>
                     <Option key={MemorizeType.Y} value={MemorizeType.Y}>
                        {MemorizeType.Y}
                     </Option>
                  </Dropdown>
               </Field>
               <Field label={'비고'}>
                  <Textarea value={remarks} onChange={(event, data) => setRemarks(data.value)} />
               </Field>
            </div>
         </DrawerBody>
         <DrawerFooter>
            <Button appearance="primary" onClick={onClickSave} disabled={isDisabled()}>
               저장
            </Button>
            <Button onClick={props.onDismiss}>닫기</Button>
         </DrawerFooter>
      </Drawer>
   );
};
