import { useContext, useEffect, useState } from 'react';
import { AuthenticationContext } from '../../contexts/context';
import { Button, Spinner, Text, tokens } from '@fluentui/react-components';
import { useCommonStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import { IRecommendSentence, IRecommendWord, MemorizeType } from './types';
import { getRecommendSentence, getRecommendWord } from '../../services/recommend';
import { SaveFilled } from '@fluentui/react-icons';
import { enWordIsOverlap, getWordCount, recentlyWord, saveWord } from '../../services/word';
import { IWord, IWordCount } from '../word/types';
import { getSentenceCount, overlapSentence, recentlySentence, saveSentence } from '../../services/sentence';
import { ISentence, ISentenceCount } from '../sentence/types';
import { ConfirmDialog } from './controls/ConfirmDialog';
import dayjs from 'dayjs';
import { successNotification, warningNotification } from '../../utils/notification.utils';
import { Adsense } from '@ctrl/react-adsense';

export const Home: React.FC = () => {
   const { authentication } = useContext(AuthenticationContext);
   const [recommendWords, setRecommendWords] = useState<IRecommendWord[]>([]);
   const [recommendSentences, setRecommendSentences] = useState<IRecommendSentence[]>([]);
   const [wordCount, setWordCount] = useState<IWordCount>({ totalWord: 0, memorizeWord: 0 });
   const [recentlyWords, setRecentlyWords] = useState<IWord[]>([]);
   const [recentlySentences, setRecentlySentences] = useState<ISentence[]>([]);
   const [sentenceCount, setSentenceCount] = useState<ISentenceCount>({ totalSentence: 0, memorizeSentence: 0 });
   const [isLoding, setIsLoding] = useState<boolean>(false);
   const [isSaveWordDialog, setIsSaveWordDialog] = useState<boolean>(false);
   const [isSaveSentenceDialog, setIsSaveSentenceDialog] = useState<boolean>(false);
   const [selectedWord, setSelectedWord] = useState<IWord>();
   const [selectedSentence, setSelectedSentence] = useState<ISentence>();
   const [isSaveLoding, setIsSaveLoding] = useState<boolean>(false);
   const navigate = useNavigate();
   const classes = useCommonStyles();

   useEffect(() => {
      if (authentication) {
         initData();
      }
   }, [authentication]); // eslint-disable-line react-hooks/exhaustive-deps

   const initData = async () => {
      setIsLoding(true);
      const [words, sentence] = await Promise.all([getRecommendWord(authentication!), getRecommendSentence(authentication!)]);
      const [wordCountRes, recentlyWordRes] = await Promise.all([getWordCount(authentication!), recentlyWord(authentication!)]);
      const [sentenceCountRes, recentlySentenceRes] = await Promise.all([
         getSentenceCount(authentication!),
         recentlySentence(authentication!),
      ]);

      if (words.isSuccess && sentence.isSuccess) {
         setRecommendWords(words.data);
         setRecommendSentences(sentence.data);
      }

      if (wordCountRes.isSuccess && recentlyWordRes.isSuccess) {
         setWordCount(wordCountRes.data);
         setRecentlyWords(recentlyWordRes.data);
      }

      if (sentenceCountRes.isSuccess && recentlySentenceRes.isSuccess) {
         setSentenceCount(sentenceCountRes.data);
         setRecentlySentences(recentlySentenceRes.data);
      }
      setIsLoding(false);
   };

   const onClickSaveWord = async (value: IRecommendWord) => {
      setSelectedWord({
         createDate: dayjs().format('YYYY-MM-DD'),
         enWord: value.enWord!,
         krWord: value.krWord!,
         userId: authentication?.user.userId!,
         isMemorize: MemorizeType.N,
         remarks: '',
      });
      const overlapResult = await enWordIsOverlap(authentication!, value.enWord!);
      if (!overlapResult.data) {
         setIsSaveWordDialog(true);
      } else {
         warningNotification('이미 등록된 단어입니다.');
      }
   };

   const onClickSaveSentence = async (value: IRecommendSentence) => {
      setSelectedSentence({
         createDate: dayjs().format('YYYY-MM-DD'),
         enSentence: value.enSentence!,
         krSentence: value.krSentence!,
         userId: authentication?.user.userId!,
         isMemorize: MemorizeType.N,
         remarks: '',
      });
      const overlapResult = await overlapSentence(authentication!, value.enSentence!);
      if (!overlapResult.data) setIsSaveSentenceDialog(true);
      else warningNotification('이미 등록된 문장입니다.');
   };

   const onSaveWord = async () => {
      setIsSaveLoding(true);
      const saveResult = await saveWord(authentication!, selectedWord!);
      if (saveResult.isSuccess) {
         const [recommendWordsRes, wordCountRes, recentlyWordRes] = await Promise.all([
            getRecommendWord(authentication!),
            getWordCount(authentication!),
            recentlyWord(authentication!),
         ]);
         if (wordCountRes.isSuccess && recentlyWordRes.isSuccess && recommendWordsRes.isSuccess) {
            setWordCount(wordCountRes.data);
            setRecentlyWords(recentlyWordRes.data);
            setRecommendWords(recommendWordsRes.data);
         }
         successNotification('등록이 완료 되었습니다.');
      }
      setIsSaveLoding(false);
      setIsSaveWordDialog(false);
   };

   const onSaveSentence = async () => {
      setIsSaveLoding(true);
      const saveResult = await saveSentence(authentication!, selectedSentence!);
      if (saveResult.isSuccess) {
         const [recommendSentencesRes, sentenceCountRes, recentlySentenceRes] = await Promise.all([
            getRecommendSentence(authentication!),
            getSentenceCount(authentication!),
            recentlySentence(authentication!),
         ]);
         if (sentenceCountRes.isSuccess && recentlySentenceRes.isSuccess && recommendSentencesRes.isSuccess) {
            setRecommendSentences(recommendSentencesRes.data);
            setSentenceCount(sentenceCountRes.data);
            setRecentlySentences(recentlySentenceRes.data);
         }
         successNotification('등록이 완료 되었습니다.');
      }
      setIsSaveLoding(false);
      setIsSaveSentenceDialog(false);
   };

   return (
      <div style={{ width: '100%', height: '100%', backgroundColor: 'RGB(233, 226, 227,0.3)' }}>
         {authentication ? (
            <div style={{ height: '100%', width: '100%' }}>
               {isLoding ? (
                  <div style={{ height: '100%', backgroundColor: 'white' }}>
                     <Spinner style={{ height: '100%' }} />
                  </div>
               ) : (
                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 20,
                        height: '100%',
                        boxSizing: 'border-box',
                        gap: 10,
                     }}
                  >
                     <div
                        style={{
                           height: 50,
                           display: 'flex',
                           paddingLeft: 10,
                           boxShadow: tokens.shadow4,
                           backgroundColor: 'white',
                           borderRadius: 5,
                           padding: 5,
                        }}
                     >
                        <Text weight="semibold" size={500}>
                           {authentication.user.name}님 환영합니다.
                        </Text>
                     </div>
                     <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: 'calc(100% - 40px)', gap: 10 }}>
                        <div
                           style={{
                              display: 'flex',
                              flexDirection: 'row',
                              width: 'calc(100% / 2)',
                              height: '100%',
                              boxSizing: 'border-box',
                           }}
                        >
                           <div
                              style={{
                                 height: '100%',
                                 width: '100%',
                                 boxShadow: tokens.shadow4,
                                 backgroundColor: 'white',
                                 borderRadius: 5,
                                 padding: 10,
                                 boxSizing: 'border-box',
                              }}
                           >
                              <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                 <div style={{ height: 30, padding: 5 }}>
                                    <Text weight="semibold" size={500}>
                                       오늘의 단어
                                    </Text>
                                 </div>
                                 <div className={classes.todayContentWrapper}>
                                    {recommendWords.map((value, index) => (
                                       <div key={`${value.enWord}_${index}`} className={classes.todayContentCardWrapper}>
                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                             <div>{value.enWord}</div>
                                             <div>{value.krWord}</div>
                                          </div>
                                          <div className={classes.todayContentButtonWrapper}>
                                             <Button
                                                appearance="subtle"
                                                icon={<SaveFilled />}
                                                style={{ minWidth: 0 }}
                                                onClick={() => onClickSaveWord(value)}
                                             />
                                          </div>
                                       </div>
                                    ))}
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div
                           style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '100%',
                              height: '100%',
                              gap: 10,
                           }}
                        >
                           <div style={{ height: 'calc(50% - 5px)' }}>
                              <div
                                 style={{
                                    height: '100%',
                                    width: '100%',
                                    boxShadow: tokens.shadow4,
                                    backgroundColor: 'white',
                                    borderRadius: 5,
                                    padding: 10,
                                    boxSizing: 'border-box',
                                 }}
                              >
                                 <div style={{ height: 30, padding: 5 }}>
                                    <Text weight="semibold" size={500}>
                                       오늘의 문장
                                    </Text>
                                 </div>
                                 <div className={classes.todayContentWrapper}>
                                    {recommendSentences.map((value, index) => (
                                       <div key={`${value.enSentence}_${index}`} className={classes.todayContentCardWrapper}>
                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                             <div>{value.enSentence}</div>
                                             <div>{value.krSentence}</div>
                                          </div>
                                          <div className={classes.todayContentButtonWrapper}>
                                             <Button
                                                appearance="subtle"
                                                icon={<SaveFilled />}
                                                style={{ minWidth: 0 }}
                                                onClick={() => onClickSaveSentence(value)}
                                             />
                                          </div>
                                       </div>
                                    ))}
                                 </div>
                              </div>
                           </div>
                           <div style={{ display: 'flex', flexDirection: 'row', height: 'calc(50% - 5px)', width: '100%', gap: 10 }}>
                              <div
                                 style={{
                                    height: '100%',
                                    width: '100%',
                                    boxShadow: tokens.shadow4,
                                    backgroundColor: 'white',
                                    borderRadius: 5,
                                    padding: 10,
                                    boxSizing: 'border-box',
                                 }}
                              >
                                 <div
                                    style={{
                                       display: 'flex',
                                       flexDirection: 'column',
                                       padding: 5,
                                       height: '100%',
                                       cursor: 'pointer',
                                    }}
                                    onClick={() => navigate('/word')}
                                 >
                                    <Text weight="semibold" size={500} style={{ padding: 5 }}>
                                       최근 추가 단어
                                    </Text>
                                    <div style={{ display: 'flex', flexDirection: 'row', padding: 5, justifyContent: 'space-between' }}>
                                       <Text weight="semibold">등록 단어수 : {wordCount?.totalWord}</Text>
                                       <Text weight="semibold">암기 단어수 : {wordCount?.memorizeWord}</Text>
                                    </div>
                                    <div
                                       style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          height: 'calc(100% - 72px)',
                                          overflowY: 'auto',
                                          padding: 10,
                                          boxSizing: 'border-box',
                                          gap: 5,
                                       }}
                                    >
                                       {recentlyWords.map((value) => (
                                          <div
                                             key={`${value.enWord}_${value.wordCode}`}
                                             style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                fontSize: 15,
                                                boxShadow: tokens.shadow4,
                                                borderRadius: 5,
                                                padding: '5px 10px',
                                                cursor: 'pointer',
                                                boxSizing: 'border-box',
                                                justifyContent: 'space-between',
                                             }}
                                          >
                                             <div>
                                                <div>{value.enWord}</div>
                                                <div>{value.krWord}</div>
                                             </div>
                                             <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                                                <Text weight="semibold" size={200}>
                                                   {value.createDate}
                                                </Text>
                                             </div>
                                          </div>
                                       ))}
                                    </div>
                                 </div>
                              </div>
                              <div
                                 style={{
                                    height: '100%',
                                    width: '100%',
                                    boxShadow: tokens.shadow4,
                                    backgroundColor: 'white',
                                    borderRadius: 5,
                                    padding: 10,
                                    boxSizing: 'border-box',
                                 }}
                              >
                                 <div
                                    style={{
                                       display: 'flex',
                                       flexDirection: 'column',
                                       padding: 5,
                                       height: '100%',
                                       cursor: 'pointer',
                                    }}
                                    onClick={() => navigate('/sentence')}
                                 >
                                    <Text weight="semibold" size={500} style={{ padding: 5 }}>
                                       최근 추가 문장
                                    </Text>
                                    <div style={{ display: 'flex', flexDirection: 'row', padding: 5, justifyContent: 'space-between' }}>
                                       <Text weight="semibold">등록 단어수 : {sentenceCount?.totalSentence}</Text>
                                       <Text weight="semibold">암기 단어수 : {sentenceCount?.memorizeSentence}</Text>
                                    </div>
                                    <div
                                       style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          height: 'calc(100% - 72px)',
                                          overflowY: 'auto',
                                          padding: 10,
                                          boxSizing: 'border-box',
                                          gap: 5,
                                       }}
                                    >
                                       {recentlySentences.map((value) => (
                                          <div
                                             key={`${value.enSentence}_${value.sentenceCode}`}
                                             style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                fontSize: 15,
                                                boxShadow: tokens.shadow4,
                                                borderRadius: 5,
                                                padding: '5px 10px',
                                                cursor: 'pointer',
                                                boxSizing: 'border-box',
                                             }}
                                          >
                                             <div style={{ width: '100%' }}>
                                                <div>{value.enSentence}</div>
                                                <div>{value.krSentence}</div>
                                                <div style={{ display: 'flex', justifyContent: 'end', padding: 10 }}>
                                                   <Text weight="semibold" size={200}>
                                                      {value.createDate}
                                                   </Text>
                                                </div>
                                             </div>
                                          </div>
                                       ))}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               )}
               
            </div>
         ) : (
            <div style={{ height: '100%', color: '#000' }}>
               <div
                  style={{
                     padding: 10,
                     width: '100%',
                     height: '100%',
                     display: 'flex',
                     flexDirection: 'column',
                     justifyContent: 'center',
                     alignItems: 'center',
                     boxSizing: 'border-box',
                     gap: 50,
                  }}
               >
                  <Text weight="bold" font="monospace" size={900}>
                     Study English with Chat Gpt!
                  </Text>
                  <Button className={classes.startButton} onClick={() => navigate('/login')}>
                     START
                  </Button>
               </div>
            </div>
         )}
         <ConfirmDialog
            isOpen={isSaveWordDialog}
            title="단어 저장"
            subText={`선택하신 "${selectedWord?.enWord}"을 단어장에 등록하시겠습니까?`}
            onDismiss={() => setIsSaveWordDialog(false)}
            onSuccess={onSaveWord}
            isLoding={isSaveLoding}
         />
         <ConfirmDialog
            isOpen={isSaveSentenceDialog}
            title="문장 저장"
            subText={`선택하신 "${selectedSentence?.enSentence}"을 문장노트에 등록하시겠습니까?`}
            onDismiss={() => setIsSaveSentenceDialog(false)}
            onSuccess={onSaveSentence}
            isLoding={isSaveLoding}
         />
      </div>
   );
};
