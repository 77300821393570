import { Avatar, tokens } from '@fluentui/react-components';
import { IAiMessage, MessageRoleType } from '../types';
import { useContext } from 'react';
import { AuthenticationContext } from '../../../contexts/context';

interface PBigConversationBox {
   message: IAiMessage;
}
export const BigConversationBox: React.FC<PBigConversationBox> = (props) => {
   const { authentication } = useContext(AuthenticationContext);

   return (
      <div>
         {props.message.role === MessageRoleType.Assistant ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5 }}>
               <Avatar icon={<>AI</>} color="peach" />
               <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', maxWidth: '80%' }}>
                  <div
                     style={{
                        backgroundColor: '#fff',
                        paddingLeft: '5px',
                        width: '0px',
                        height: '0px',
                        borderTop: `8px solid ${tokens.colorBrandBackground}`,
                        borderBottom: `8px solid ${tokens.colorBrandBackground}`,
                        borderLeft: '0px solid transparent',
                        borderRight: '8px solid #fff',
                     }}
                  ></div>
                  <div style={{ backgroundColor: '#fff', padding: 8, borderRadius: 5 }}>{props.message.content}</div>
               </div>
            </div>
         ) : (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5, justifyContent: 'end' }}>
               <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', maxWidth: '80%' }}>
                  <div style={{ backgroundColor: 'rgb(252,236,92)', padding: 8, borderRadius: 5, wordBreak: 'break-all' }}>
                     {props.message.content}
                  </div>
                  <div
                     style={{
                        backgroundColor: '#fff',
                        paddingLeft: '5px',
                        width: '0px',
                        height: '0px',
                        borderTop: `8px solid ${tokens.colorBrandBackground}`,
                        borderBottom: `8px solid ${tokens.colorBrandBackground}`,
                        borderLeft: '8px solid  rgb(252,236,92)',
                        borderRight: '0px solid transparent',
                     }}
                  ></div>
               </div>
               <Avatar icon={`${authentication?.user.name[0].toUpperCase()}${authentication?.user.name[1].toUpperCase()}`} color="peach" />
            </div>
         )}
         {/* {props.message.content} */}
      </div>
   );
};
