import {
   Title2,
   Text,
   Button,
   Dropdown,
   Option,
   Input,
   DataGrid,
   TableColumnDefinition,
   createTableColumn,
   TableCellLayout,
   TableRowId,
   DataGridHeader,
   DataGridRow,
   DataGridHeaderCell,
   DataGridBody,
   DataGridCell,
   Spinner,
   Switch,
   Label,
   Slider,
   Menu,
   MenuTrigger,
   MenuPopover,
   MenuList,
   tokens,
} from '@fluentui/react-components';
import { useCommonStyles } from '../common/styles';
import { useSentenceStyles } from './styles';
import { useContext, useEffect, useState } from 'react';
import { ISentence, ISentenceCount, SentenceEditorType, SentenceSearchColumn } from './types';
import { deleteSentence, getSentenceCount, getSentenceList } from '../../services/sentence';
import { AuthenticationContext } from '../../contexts/context';
import { DeleteFilled, EditFilled, AddSquareFilled, SearchFilled, StopFilled, PlayFilled, SettingsFilled } from '@fluentui/react-icons';
import { ConfirmDialog } from '../common/controls/ConfirmDialog';
import { SentenceEditor } from './controls/SentenceEditor';
import { Pagination } from '../common/controls/Pagination';
import { textToSpeak } from '../../utils/tts.utils';
import { TextToSpeakLanguage } from '../common/types';

const options = [
   { value: SentenceSearchColumn.EnSentence, text: '영문장' },
   { value: SentenceSearchColumn.KrSentence, text: '해석' },
   { value: SentenceSearchColumn.Remarks, text: '비고' },
];

export const Sentence: React.FC = () => {
   const classes = useSentenceStyles();
   const { authentication } = useContext(AuthenticationContext);
   const [sentences, setSentences] = useState<ISentence[]>([]);
   const [countSentence, setCountSentence] = useState<ISentenceCount>({ memorizeSentence: 0, totalSentence: 0 });
   const [searchColumn, setSearchColumn] = useState<SentenceSearchColumn>(SentenceSearchColumn.EnSentence);
   const [currentPage, setCurrentPage] = useState<number>(1);
   const [totalPage, setTotalPage] = useState<number>(1);
   const [searchText, setSearchText] = useState<string>('');
   const [editorType, setEditorType] = useState<SentenceEditorType>();
   const [isEditor, setIsEditor] = useState<boolean>(false);
   const [selectedItems, setSelectedItems] = useState<number[]>([]);
   const [selectedSentences, setSelectedSentences] = useState<ISentence[]>([]);
   const [isDataLoaded, setIsDataLoaded] = useState<boolean>(true);
   const [isDeleteConfirm, setIsDeleteConfirm] = useState<boolean>(false);
   const [isOnlyEnglish, setIsOnlyEnglish] = useState<boolean>(false);
   const [rate, setRate] = useState<number>(1);
   const [isSpeaking, setIsSpeaking] = useState<boolean>(false);

   useEffect(() => {
      getSentenceItems();
      getCount();
      window.speechSynthesis.cancel();
      window.speechSynthesis.getVoices();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const getSentenceItems = async () => {
      setIsDataLoaded(false);
      const result = await getSentenceList(authentication!, searchColumn, searchText, currentPage);
      if (result.isSuccess) {
         setSentences(result.data.sentences);
         setTotalPage(result.data.totalPage);
      }
      setIsDataLoaded(true);
   };

   const getCount = async () => {
      const result = await getSentenceCount(authentication!);
      if (result.isSuccess) {
         setCountSentence(result.data);
      }
   };

   const columns: TableColumnDefinition<ISentence>[] = [
      createTableColumn<ISentence>({
         columnId: 'index',
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>No</div>;
         },
         renderCell: (sentence) => {
            return (
               <TableCellLayout style={{ justifyContent: 'center' }}>
                  {sentences.findIndex((element) => element.sentenceCode === sentence.sentenceCode) + 1}
               </TableCellLayout>
            );
         },
      }),
      createTableColumn<ISentence>({
         columnId: 'enSentence',
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>영문장</div>;
         },
         renderCell: (sentence) => {
            return <TableCellLayout style={{ justifyContent: 'center' }}>{sentence.enSentence}</TableCellLayout>;
         },
      }),
      createTableColumn<ISentence>({
         columnId: 'krSentence',
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>해석</div>;
         },
         renderCell: (sentence) => {
            return <TableCellLayout style={{ justifyContent: 'center' }}>{sentence.krSentence}</TableCellLayout>;
         },
      }),
      createTableColumn<ISentence>({
         columnId: 'createDate',
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>등록일</div>;
         },
         renderCell: (sentence) => {
            return <TableCellLayout style={{ justifyContent: 'center' }}>{sentence.createDate}</TableCellLayout>;
         },
      }),
      createTableColumn<ISentence>({
         columnId: 'isMemorize',
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>암기 여부</div>;
         },
         renderCell: (sentence) => {
            return <TableCellLayout style={{ justifyContent: 'center' }}>{sentence.isMemorize}</TableCellLayout>;
         },
      }),
      createTableColumn<ISentence>({
         columnId: 'remarks',
         renderHeaderCell: () => {
            return <div style={{ width: '100%' }}>비고</div>;
         },
         renderCell: (sentence) => {
            return <TableCellLayout style={{ justifyContent: 'center' }}>{sentence.remarks}</TableCellLayout>;
         },
      }),
   ];

   const columnSizingOptions = {
      index: {
         defaultWidth: 30,
         minWidth: 30,
      },
      enSentence: {
         defaultWidth: 350,
         minWidth: 100,
      },
      krSentence: {
         defaultWidth: 350,
         minWidth: 100,
      },
      createDate: {
         defaultWidth: 120,
         minWidth: 50,
      },
      isMemorize: {
         defaultWidth: 30,
         minWidth: 30,
      },
      remarks: {
         minWidth: 100,
      },
   };

   const onDeleteSentence = async () => {
      const result = await deleteSentence(authentication!, selectedSentences);
      if (result.isSuccess) {
         getSentenceItems();
         getCount();
      }
      setIsDeleteConfirm(false);
      setSelectedItems([]);
      setSelectedSentences([]);
   };

   const onChangeSentence = (sentence: ISentence) => {
      let newSentences: ISentence[] = [...sentences];
      const index = newSentences.findIndex((element) => element.sentenceCode === sentence.sentenceCode);
      if (index > -1) {
         newSentences.splice(index, 1, sentence);
         setSentences(newSentences);
         getCount();
      } else {
         getSentenceItems();
         getCount();
      }
      setEditorType(undefined);
      setIsEditor(false);
   };

   const startSpeak = async () => {
      setIsSpeaking(true);
      if (isOnlyEnglish) {
         for (const element of selectedSentences) {
            await textToSpeak(element.enSentence, TextToSpeakLanguage.En, rate);
         }
      } else {
         for (const element of selectedSentences) {
            await textToSpeak(element.enSentence, TextToSpeakLanguage.En, rate);
            await textToSpeak(element.krSentence, TextToSpeakLanguage.Kr, rate);
         }
      }
      setIsSpeaking(false);
   };

   const stopSpeak = () => {
      setIsSpeaking(false);
      window.speechSynthesis.cancel();
   };

   return (
      <div className={classes.wrapper}>
         <div className={classes.contentWrapper}>
            <div className={useCommonStyles().titleWrapper}>
               <Title2>문장노트</Title2>
            </div>
            <div className={useCommonStyles().countWrapper}>
               <Text weight="semibold">등록 문장 : {countSentence.totalSentence}</Text>
               <Text weight="semibold">암기 문장 : {countSentence.memorizeSentence}</Text>
            </div>
            <div className={classes.toolsWrapper}>
               <div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', paddingBottom: 5 }}>
                     <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Button
                           appearance="transparent"
                           icon={isSpeaking ? <StopFilled /> : <PlayFilled />}
                           onClick={isSpeaking ? stopSpeak : startSpeak}
                           style={{ minWidth: 0, padding: 0 }}
                           disabled={selectedItems.length === 0}
                        >
                           {isSpeaking ? '정지' : '듣기'}
                        </Button>
                        <Menu positioning={'before-bottom'}>
                           <MenuTrigger>
                              <Button
                                 icon={<SettingsFilled />}
                                 appearance="transparent"
                                 style={{ minWidth: 0, padding: 0, paddingLeft: 5 }}
                              >
                                 설정
                              </Button>
                           </MenuTrigger>
                           <MenuPopover>
                              <MenuList>
                                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Switch
                                       checked={isOnlyEnglish}
                                       onChange={(event, data) => setIsOnlyEnglish(data.checked!)}
                                       label={'영어만'}
                                       style={{ fontWeight: tokens.fontWeightSemibold }}
                                    />
                                    <Slider value={rate} max={1.5} min={0.5} step={0.5} onChange={(event, data) => setRate(data.value)} />
                                    <Label weight="semibold">속도 x{rate}</Label>
                                 </div>
                              </MenuList>
                           </MenuPopover>
                        </Menu>
                     </div>
                  </div>
                  <div className={classes.toolsEditContentWrapper}>
                     <Button
                        appearance="transparent"
                        icon={<AddSquareFilled />}
                        onClick={() => {
                           setIsEditor(true);
                           setEditorType(SentenceEditorType.Add);
                        }}
                        style={{ minWidth: 0, paddingLeft: 0, paddingRight: 0 }}
                     >
                        추가
                     </Button>
                     <Button
                        appearance="transparent"
                        icon={<EditFilled />}
                        style={{ minWidth: 0, paddingLeft: 0, paddingRight: 0 }}
                        onClick={() => {
                           setIsEditor(true);
                           setEditorType(SentenceEditorType.Update);
                        }}
                        disabled={selectedItems.length !== 1}
                     >
                        수정
                     </Button>
                     <Button
                        appearance="transparent"
                        icon={<DeleteFilled />}
                        style={{ minWidth: 0, paddingLeft: 0, paddingRight: 0 }}
                        onClick={() => setIsDeleteConfirm(true)}
                        disabled={selectedItems.length <= 0 || editorType === SentenceEditorType.Update}
                     >
                        삭제
                     </Button>
                  </div>
               </div>
               <div className={classes.toolsEditContentWrapper}>
                  <Dropdown
                     value={options.find((element) => element.value === searchColumn)?.text}
                     onOptionSelect={(event, data) => setSearchColumn(data.optionValue as SentenceSearchColumn)}
                     style={{ minWidth: '100px' }}
                  >
                     {options.map((option, index) => (
                        <Option key={option.value} value={option.value}>
                           {option.text}
                        </Option>
                     ))}
                  </Dropdown>
                  <Input
                     placeholder="검색할 값을 입력해주세요"
                     value={searchText}
                     onChange={(event, data) => setSearchText(data.value)}
                     onKeyDown={(event) => {
                        if (event.code === 'Enter') getSentenceItems();
                     }}
                  />
                  <Button appearance="primary" icon={<SearchFilled />}>
                     검색
                  </Button>
               </div>
            </div>
            <DataGrid
               items={sentences}
               columns={columns}
               selectedItems={selectedItems}
               selectionMode="multiselect"
               columnSizingOptions={columnSizingOptions}
               onSelectionChange={(event, data) => {
                  const set = new Set<TableRowId>(data.selectedItems);
                  const indexs = Array.from(set) as number[];
                  let newSelectedSentences: ISentence[] = [];
                  indexs.forEach((index) => {
                     newSelectedSentences.push(sentences[index]);
                  });
                  setSelectedItems(indexs);
                  setSelectedSentences(newSelectedSentences);
               }}
               style={{ height: '100%' }}
               size="small"
               sortable
               resizableColumns
            >
               <DataGridHeader style={{ textAlign: 'center' }}>
                  <DataGridRow>{({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}</DataGridRow>
               </DataGridHeader>
               {isDataLoaded ? (
                  <div style={{ cursor: 'pointer' }}>
                     <DataGridBody<ISentence>>
                        {({ item, rowId }) => (
                           <DataGridRow<ISentence> key={rowId}>
                              {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
                           </DataGridRow>
                        )}
                     </DataGridBody>
                  </div>
               ) : (
                  <Spinner size="small" style={{ height: '100%' }} />
               )}
            </DataGrid>
            <Pagination currentPage={currentPage} totalPage={totalPage} onChangeCurrentPage={setCurrentPage} />
         </div>
         <SentenceEditor
            isOpen={isEditor}
            sentence={selectedSentences[0]}
            type={editorType}
            onDismiss={() => setIsEditor(false)}
            onChangeSentence={onChangeSentence}
         />
         <ConfirmDialog
            isOpen={isDeleteConfirm}
            title="삭제"
            subText="정말로 삭제하시겠습니까?"
            onSuccess={onDeleteSentence}
            onDismiss={() => setIsDeleteConfirm(false)}
         />
      </div>
   );
};
