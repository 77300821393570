export interface IAuthenticationContext {
   authentication?: IAuthentication;
   setAuthentication?: (authentication: IAuthentication) => void;
   login?: (authentication: IAuthentication) => void;
   logout?: () => void;
}

export interface IAuthentication {
   accessToken: string;
   expireDate: Date;
   user: IUser;
}

export interface IUser {
   name: string;
   mail: string;
   userId: string;
   password?: string;
   roles?: IRole[];
   isDeleted?: string;
}

export interface IRole {
   roleCode: string;
   name: RoleName;
   description?: string;
}

export const enum RoleName {
   Admin = 'ROLE_ADMIN',
   User = 'ROLE_USER',
}

export interface IMenuList {
   key: string;
   url: string;
   name: string;
}

export const enum MemorizeType {
   N = 'N',
   Y = 'Y',
}

export const enum TextToSpeakLanguage {
   Kr = 'ko-KR',
   En = 'en-US',
}

export interface IRecommendWord {
   recommendCode?:number,
   enWord?:string,
   krWord?:string,
   userId:string,
   recommendDate?: string,
   recommendStoreCode?:number
}

export interface IRecommendSentence {
   recommendCode?:number,
   enSentence?:string,
   krSentence?:string,
   userId:string,
   recommendDate?: string,
   recommendStoreCode?:number
}

export type ValidationStateType = "none" | "error" | "warning" | "success" | undefined;
