import { useContext, useRef, useState } from 'react';
import { useCommonStyles } from './styles';
import { Button, Input, Link, tokens } from '@fluentui/react-components';
import { errorNotification, warningNotification } from '../../utils/notification.utils';
import { requestLogin } from '../../services/user';
import { setRefreshToken } from '../../utils/common.utils';
import { AuthenticationContext } from '../../contexts/context';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

export const Login: React.FC = () => {
   const classes = useCommonStyles();
   const { login } = useContext(AuthenticationContext);
   const [userId, setUserId] = useState<string>('');
   const [password, setPassword] = useState<string>('');
   const navigate = useNavigate();
   const userIdInputRef = useRef<HTMLInputElement>(null);
   const passwordInputRef = useRef<HTMLInputElement>(null);

   const onClickLogin = async () => {
      if (userId === '') {
         warningNotification('아이디를 입력해주세요');
         userIdInputRef.current?.focus();
      } else if (password === '') {
         warningNotification('비밀번호를 입력하세요');
         passwordInputRef.current?.focus();
      } else {
         const response = await requestLogin(userId, password);
         if (response.isSuccess) {
            setRefreshToken(response.data.refreshToken, response.data.refreshTokenExpireTime);
            login!({
               accessToken: response.data.accessToken,
               expireDate: dayjs().add(response.data.accessTokenExpireTime, 'millisecond').toDate(),
               user: response.data.user,
            });
         } else {
            errorNotification('아이디 또는 비밀번호 확인해 주세요', 'top-center');
            setUserId('');
            setPassword('');
         }
      }
   };

   const onKeyDownLogin = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.code === 'Enter') onClickLogin();
   };

   return (
      <div className={classes.loginWrapper}>
         <div className={classes.loginBoxWrapper}>
            <div className={classes.loginBoxTitleWrapper}>
               <span className={classes.loginTitle}>ES Talk</span>
            </div>
            <div className={classes.loginInputBoxWrapper}>
               <Input
                  ref={userIdInputRef}
                  placeholder="ID를 입력해주세요"
                  value={userId}
                  size="large"
                  onChange={(event, data) => setUserId(data.value)}
                  onKeyPress={onKeyDownLogin}
               />
               <Input
                  ref={passwordInputRef}
                  type="password"
                  placeholder="비밀번호를 입력해주세요"
                  value={password}
                  size="large"
                  onChange={(event, data) => setPassword(data.value)}
                  onKeyPress={onKeyDownLogin}
               />
               <div className={classes.loginLinkWrapper}>
                  <Link onClick={()=> navigate('/findpassword')}>비밀번호 찾기</Link>
                  <Link onClick={()=> navigate('/signup')}>회원가입</Link>
               </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '60px', padding: '0px 20px' }}>
               <Button
                  style={{ width: '100%', fontWeight: tokens.fontWeightSemibold, height: 40 }}
                  appearance="primary"
                  onClick={onClickLogin}
               >
                  로그인
               </Button>
            </div>
         </div>
      </div>
   );
};
