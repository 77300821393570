import {
   Button,
   Field,
   Input,
   InputOnChangeData,
   Table,
   TableBody,
   TableCell,
   TableHeader,
   TableHeaderCell,
   TableRow,
   Text,
   tokens,
} from '@fluentui/react-components';
import { ChevronDownFilled, ChevronLeftFilled, PlayFilled, StopFilled } from '@fluentui/react-icons';
import { useContext, useEffect, useState } from 'react';
import { isValidCron } from 'cron-validator';
import cronstrue from 'cronstrue/i18n';
import { ValidationStateType } from '../../common/types';
import { ScheduleType } from '../types';
import { getSchedule, sentenceScheduleStart, sentenceScheduleStop, wordScheduleStart, wordScheduleStop } from '../../../services/admin';
import { AuthenticationContext } from '../../../contexts/context';
import { ApiResult } from '../../../services/types';

const explanCronColumns = [
   { key: 'minutes', label: '분' },
   { key: 'hours', label: '시' },
   { key: 'day', label: '일' },
   { key: 'month', label: '월' },
   { key: 'week', label: '요일' },
   { key: 'year', label: '연도' },
];

const specialColumns = [
   { key: 'all', label: '*', description: '모든 값' },
   { key: 'none', label: '?', description: '특정한 값이 없음' },
   { key: 'range', label: '-', description: '범위 ex) MON-WED' },
   { key: 'special', label: ',', description: '특정 값. ex) MON,WED,FRI' },
   { key: 'unit', label: '/', description: '시작시간 / 단위 ex) */5' },
   { key: 'last', label: 'L', description: '마지막 일,요일' },
   { key: 'close', label: 'W', description: '가장 가까운 평일 ex) 15W' },
   { key: 'weekof', label: '#', description: '몇째주의 무슨 요일을 표현 ex) 3#2(2번째주 수요일)' },
];

export const Schedule: React.FC = () => {
   const { authentication } = useContext(AuthenticationContext);
   const [wordCron, setWordCron] = useState<string>('');
   const [sentenceCron, setSentenceCron] = useState<string>('');
   const [wordCronText, setWordCronText] = useState<string>('');
   const [sentenceCronText, setSentenceCronText] = useState<string>('');
   const [wordScheduleState, setWordScheduleState] = useState<boolean>(false);
   const [sentenceScheduleState, setSentenceScheduleState] = useState<boolean>(false);

   const [validationStateWord, setValidationStateWord] = useState<ValidationStateType>('none');
   const [validationMessageWord, setValidationMessageWord] = useState<string>();
   const [validationStateSentence, setValidationStateSentence] = useState<ValidationStateType>('none');
   const [validationMessageSentence, setValidationMessageSentence] = useState<string>();

   const [isExpand, setIsExpand] = useState<boolean>(false);
   const [isWordExpand, setIsWordExpand] = useState<boolean>(false);
   const [isSentenceExpand, setIsSentenceExpand] = useState<boolean>(false);

   useEffect(() => {
      getScheduleAll();
   }, []); // eslint-disable-line react-hooks/exhaustive-deps

   const getScheduleAll = async () => {
      const result = await getSchedule(authentication!);
      if (result.isSuccess && result.data.length > 0) {
         result.data.forEach((value: any) => {
            if (value.scheduledName === 'WORD') {
               setWordCron(value.cron);

               if (
                  isValidCron(value.cron, { seconds: true }) &&
                  value.cron.split(' ').length !== 5 &&
                  value.cron.split(' ')[value.cron.split(' ').length - 1]
               ) {
                  vaildationState(ScheduleType.Word, value.cron, false);
               } else {
                  vaildationState(ScheduleType.Word, value.cron, true);
               }
               setWordScheduleState(value.state === 'START');
               setWordCronText(cronstrue.toString(value.cron, { locale: 'ko' }));
            } else {
               setSentenceCron(value.cron);
               if (
                  isValidCron(value.cron, { seconds: true }) &&
                  value.cron.split(' ').length !== 5 &&
                  value.cron.split(' ')[value.cron.split(' ').length - 1]
               ) {
                  vaildationState(ScheduleType.Sentence, value.cron, false);
               } else {
                  vaildationState(ScheduleType.Sentence, value.cron, true);
               }
               setSentenceCronText(cronstrue.toString(value.cron, { locale: 'ko' }));
               setSentenceScheduleState(value.state === 'START');
            }
         });
      }
   };

   const onChangeWordCron = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      if (
         isValidCron(data.value, { seconds: true }) &&
         data.value.split(' ').length !== 5 &&
         data.value.split(' ')[data.value.split(' ').length - 1]
      ) {
         vaildationState(ScheduleType.Word, data.value, false);
      } else {
         vaildationState(ScheduleType.Word, data.value, true);
      }
      setWordCron(data.value);
   };

   const onChangeSentenceCron = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
      if (
         isValidCron(data.value, { seconds: true }) &&
         data.value.split(' ').length !== 5 &&
         data.value.split(' ')[data.value.split(' ').length - 1]
      ) {
         vaildationState(ScheduleType.Sentence, data.value, false);
      } else {
         vaildationState(ScheduleType.Sentence, data.value, true);
      }
      setSentenceCron(data.value);
   };

   const vaildationState = (scheduleType: ScheduleType, cron: string, isError: boolean) => {
      switch (scheduleType) {
         case ScheduleType.Word:
            if (cron) {
               if (isError) {
                  setValidationStateWord('error');
                  setValidationMessageWord('올바른 표현식이 아닙니다.');
                  setWordCronText('');
               } else {
                  setValidationStateWord('success');
                  setValidationMessageWord('올바른 표현식 입니다!');
                  setWordCronText(cronstrue.toString(cron, { locale: 'ko' }));
               }
            }
            break;
         case ScheduleType.Sentence:
            if (cron) {
               if (isError) {
                  setValidationStateSentence('error');
                  setValidationMessageSentence('올바른 표현식이 아닙니다.');
                  setSentenceCronText('');
               } else {
                  setValidationStateSentence('success');
                  setValidationMessageSentence('올바른 표현식 입니다!');
                  setSentenceCronText(cronstrue.toString(cron, { locale: 'ko' }));
               }
            }
            break;
      }
   };

   const onClickStartSchedule = async (type: ScheduleType) => {
      switch (type) {
         case ScheduleType.Word:
            if (wordCron && validationStateWord === 'success') {
               const result = await wordScheduleStart(authentication!, {
                  cron: wordCron,
                  userId: authentication?.user.userId!,
               });
               if (result.isSuccess) {
                  getScheduleAll();
               }
            }
            break;
         case ScheduleType.Sentence:
            if (sentenceCron && validationStateSentence === 'success') {
               const result = await sentenceScheduleStart(authentication!, {
                  cron: sentenceCron,
                  userId: authentication?.user.userId!,
               });
               if (result.isSuccess) {
                  getScheduleAll();
               }
            }
            break;
      }
   };

   const onClickStopSchedule = async (type: ScheduleType) => {
      let result: ApiResult;
      switch (type) {
         case ScheduleType.Word:
            result = await wordScheduleStop(authentication!);
            break;
         case ScheduleType.Sentence:
            result = await sentenceScheduleStop(authentication!);
            break;
      }
      if (result.isSuccess) {
         getScheduleAll();
      }
   };

   return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: 5 }}>
         <div
            style={{
               boxShadow: tokens.shadow4,
               boxSizing: 'border-box',
               padding: 10,
               display: 'flex',
               flexDirection: 'column',
               borderRadius: 10,
            }}
         >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
               <Text size={500} weight="semibold">
                  Cron 표현식
               </Text>
               <Button
                  appearance="transparent"
                  icon={isExpand ? <ChevronDownFilled /> : <ChevronLeftFilled />}
                  onClick={() => setIsExpand(!isExpand)}
               />
            </div>
            {isExpand && (
               <div style={{ padding: 10, display: 'flex', gap: 20 }}>
                  <div style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', width: '50%' }}>
                     <Table>
                        <TableHeader>
                           <TableRow style={{ textAlign: 'center' }}>
                              {explanCronColumns.map((value) => (
                                 <TableHeaderCell key={`col_h_${value.key}`} button={{ style: { justifyContent: 'center' } }}>
                                    {value.label}
                                 </TableHeaderCell>
                              ))}
                           </TableRow>
                        </TableHeader>
                        <TableBody>
                           <TableRow>
                              {explanCronColumns.map((value) => (
                                 <TableCell key={`col_r_${value.key}`} style={{ textAlign: 'center' }}>
                                    *
                                 </TableCell>
                              ))}
                           </TableRow>
                        </TableBody>
                     </Table>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #e0e0e0', borderBottom: 0, width: '50%' }}>
                     {specialColumns.map((value) => (
                        <div
                           key={`col_h_${value.key}`}
                           style={{
                              display: 'flex',
                              fontSize: 15,
                              boxSizing: 'border-box',
                              height: 45,
                              width: '100%',
                              borderBottom: '1px solid #e0e0e0',
                           }}
                        >
                           <div
                              style={{
                                 display: 'flex',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                                 width: 60,
                                 borderRight: '1px solid #e0e0e0',
                              }}
                           >
                              {value.label}
                           </div>
                           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                              {value.description}
                           </div>
                        </div>
                     ))}
                  </div>
               </div>
            )}
         </div>
         <div style={{ display: 'flex', gap: 10, height: '100%' }}>
            <div
               style={{
                  boxShadow: tokens.shadow4,
                  boxSizing: 'border-box',
                  padding: 10,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 5,
                  borderRadius: 10,
                  height: '100%',
                  width: '50%',
               }}
            >
               <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Text size={500} weight="semibold">
                     단어 수집
                  </Text>
                  <div style={{ display: 'flex', gap: 10, padding: 5 }}>
                     <Button
                        appearance="transparent"
                        icon={<StopFilled color="darkred" />}
                        onClick={() => onClickStopSchedule(ScheduleType.Word)}
                        disabled={!wordScheduleState}
                        style={{ minWidth: 0, padding: 0 }}
                     >
                        정지
                     </Button>
                     <Button
                        appearance="transparent"
                        icon={<PlayFilled color={tokens.colorBrandBackground} />}
                        onClick={() => onClickStartSchedule(ScheduleType.Word)}
                        disabled={!wordCron || validationStateWord === 'error'}
                        style={{ minWidth: 0, padding: 0 }}
                     >
                        {wordScheduleState ? '재시작' : '시작'}
                     </Button>
                  </div>
               </div>
               <Text size={400} weight="semibold">
                  {wordCronText} / 반복
               </Text>
               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 5, gap: 10 }}>
                  {wordScheduleState ? (
                     <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                        <Text size={400} weight="semibold" style={{ color: tokens.colorBrandBackground }}>
                           작동중
                        </Text>
                        <PlayFilled color={tokens.colorBrandBackground} />
                     </div>
                  ) : (
                     <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                        <Text size={400} weight="semibold" style={{ color: 'darkred' }}>
                           중지
                        </Text>
                        <StopFilled color="darkred" />
                     </div>
                  )}
                  <Button
                     appearance="subtle"
                     icon={isWordExpand ? <ChevronLeftFilled /> : <ChevronDownFilled />}
                     onClick={() => setIsWordExpand(!isWordExpand)}
                  />
               </div>
               <div style={{ display: isWordExpand ? 'none' : 'flex', flexDirection: 'row', alignItems: 'center', gap: 10, padding: 5 }}>
                  <Field validationState={validationStateWord} validationMessage={validationMessageWord}>
                     <Input placeholder="Cron" value={wordCron} onChange={onChangeWordCron} />
                  </Field>
               </div>
            </div>
            <div
               style={{
                  boxShadow: tokens.shadow4,
                  boxSizing: 'border-box',
                  padding: 10,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 5,
                  borderRadius: 10,
                  height: '100%',
                  width: '50%',
               }}
            >
               <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Text size={500} weight="semibold">
                     문장 수집
                  </Text>
                  <div style={{ display: 'flex', gap: 10, padding: 5 }}>
                     <Button
                        appearance="transparent"
                        icon={<StopFilled color="darkred" />}
                        onClick={() => onClickStopSchedule(ScheduleType.Sentence)}
                        disabled={!sentenceScheduleState}
                        style={{ minWidth: 0, padding: 0 }}
                     >
                        정지
                     </Button>
                     <Button
                        appearance="transparent"
                        icon={<PlayFilled color={tokens.colorBrandBackground} />}
                        onClick={() => onClickStartSchedule(ScheduleType.Sentence)}
                        disabled={!sentenceCron || validationStateSentence === 'error'}
                        style={{ minWidth: 0, padding: 0 }}
                     >
                        {sentenceScheduleState ? '재시작' : '시작'}
                     </Button>
                  </div>
               </div>
               <Text size={400} weight="semibold">
                  {sentenceCronText} / 반복
               </Text>
               <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 5, gap: 10 }}>
                  {sentenceScheduleState ? (
                     <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                        <Text size={400} weight="semibold" style={{ color: tokens.colorBrandBackground }}>
                           작동중
                        </Text>
                        <PlayFilled color={tokens.colorBrandBackground} />
                     </div>
                  ) : (
                     <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                        <Text size={400} weight="semibold" style={{ color: 'darkred' }}>
                           중지
                        </Text>
                        <StopFilled color="darkred" />
                     </div>
                  )}
                  <Button
                     appearance="subtle"
                     icon={isSentenceExpand ? <ChevronLeftFilled /> : <ChevronDownFilled />}
                     onClick={() => setIsSentenceExpand(!isSentenceExpand)}
                  />
               </div>
               <div
                  style={{ display: isSentenceExpand ? 'none' : 'flex', flexDirection: 'row', alignItems: 'center', gap: 10, padding: 5 }}
               >
                  <Field validationState={validationStateSentence} validationMessage={validationMessageSentence}>
                     <Input placeholder="Cron" value={sentenceCron} onChange={onChangeSentenceCron} />
                  </Field>
               </div>
            </div>
         </div>
      </div>
   );
};
