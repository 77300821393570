import { makeStyles, shorthands, tokens } from '@fluentui/react-components';

export const useCommonStyles = makeStyles({
   layoutWrapper: {
      width: '100%',
      height: '100%',
      ...shorthands.overflow('hidden'),
   },
   layoutTopBarWrapper: {
      userSelect: 'none',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '60px',
      backgroundColor: '#24292e',
      ...shorthands.paddingInline('20px'),
      // ...shorthands.borderBottom('1px', 'solid', '#e0e0e0'),
   },
   layoutTopNavIcon: {
      color: 'white',
      cursor: 'pointer',
   },
   layoutTopLogo: {
      color: 'white',
      cursor: 'pointer',
      fontSize: tokens.fontSizeBase600,
      fontWeight: tokens.fontWeightSemibold,
   },
   layoutTopUserState: {
      color: 'white',
      cursor: 'pointer',
      fontSize: tokens.fontSizeBase300,
      fontWeight: tokens.fontWeightSemibold,
      ':hover': {
         color: tokens.colorBrandBackgroundHover,
      },
   },
   layoutSidePenalHeaderTitle: {
      userSelect: 'none',
      cursor: 'pointer',
      ':hover': {
         color: tokens.colorBrandBackgroundPressed,
      },
   },
   layoutSidePenalItemRoot: {
      width: '100%',
      height: '40px',
      justifyContent: 'start',
   },
   layoutSidePenalItemSelect: {
      color: tokens.colorBrandBackgroundPressed,
      backgroundColor: tokens.colorBrandBackgroundInvertedSelected,
      fontWeight: tokens.fontWeightBold,
   },
   layoutSidePenalWrapper: {
      display: 'flex',
      flexDirection: 'column',
      ...shorthands.gap('1px'),
      ...shorthands.margin('30px', '0px'),
   },
   layoutSidePenalCardHeader: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      ...shorthands.gap('10px'),
   },
   layoutSidePenalCardFooter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
   },
   layoutContentWrapper: {
      width: '100%',
      height: 'calc(100% - 61px)',
      overflowY:'auto'
   },
   loginWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
   },
   loginBoxWrapper: {
      width: '400px',
      height: '300px',
      ...shorthands.padding('10px'),
      ...shorthands.border('1px', 'solid', '#e0e0e0'),
      ...shorthands.borderRadius('5px'),
   },
   loginBoxTitleWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignContent: 'center',
      userSelect: 'none',
      height: '25px',
      ...shorthands.padding('10px'),
   },
   loginTitle: {
      fontWeight: tokens.fontWeightSemibold,
      fontSize: tokens.fontSizeHero700,
      userSelect: 'none',
   },
   loginInputBoxWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '160px',
      ...shorthands.padding('20px'),
      ...shorthands.gap('10px'),
   },
   loginLinkWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
   },
   titleWrapper: {
      marginBottom: '20px',
   },
   countWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'end',
      paddingBottom: '0px',
      ...shorthands.gap('5px'),
      ...shorthands.padding('0px', '10px'),
   },
   paginationWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'end',
      ...shorthands.padding('20px', '0px'),
   },
   paginationButtonRoot: {
      minWidth: 0,
      fontWeight: tokens.fontWeightSemibold,
      fontSize: '20px',
      ...shorthands.padding('8px'),
   },
   paginationButtonSelected: {
      color: 'white',
      fontWeight: tokens.fontWeightBold,
   },
   startButton: {
      backgroundColor: 'rgb(36, 41, 46)',
      color: 'white',
      width: '150px',
      height: '50px',
      fontSize: '23px',
      fontWeight: tokens.fontWeightBold,
      ...shorthands.border('0px'),
   },
   todayContentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 30px)',
      overflowY: 'auto',
      boxSizing: 'border-box',
      ...shorthands.gap('5px'),
      ...shorthands.padding('15px'),
   },
   todayContentCardWrapper: {
      display: 'flex',
      fontSize: '16px',
      boxShadow: tokens.shadow4,
      justifyContent: 'space-between',
      height: '60px',
      cursor: 'pointer',
      ...shorthands.borderRadius('5px'),
      ...shorthands.padding('10px'),
      ...shorthands.gap('10px'),
      ':hover':{
         fontWeight:tokens.fontWeightSemibold,
         backgroundColor:tokens.colorBrandBackground2Hover
      }
   },
   todayContentButtonWrapper: {
      display: 'flex',
      flexDirection: 'row',
      ...shorthands.gap('2px')
   },
});
