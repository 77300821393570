export const enum MessageRoleType {
   Assistant = 'assistant',
   User = 'user',
}

export interface IAiConversation {
   messages: IAiMessage[];
   startDate?: string;
}

export interface IAiMessage {
   role: MessageRoleType;
   content: string;
}